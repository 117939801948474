import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface TextLinkProps {
  to?: string;
  onClick?: () => void;
  internal?: boolean;
  newTab?: boolean;
  routerLinkProps?: LinkProps;
  className?: ClassValue;
  children: ReactNode;
  color?: 'light' | 'dark';
}

const TextLink: FC<TextLinkProps> = ({
  to,
  onClick,
  internal = true,
  newTab,
  className,
  routerLinkProps,
  children,
  color,
}) => {
  const styles = cn(
    'text-teal-500 hover:text-teal-600 cursor-pointer focus:outline-ring',
    {
      'text-teal-foreground hover:text-teal-300': color === 'light',
    },
    className
  );

  if (!to) {
    return (
      <span
        className={styles}
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (onClick && (e.key === 'Enter' || e.key === ' ')) {
            onClick();
          }
        }}
      >
        {children}
      </span>
    );
  }

  return internal ? (
    <Link
      to={to}
      className={styles}
      {...routerLinkProps}
      target={newTab ? '_blank' : undefined}
    >
      {children}
    </Link>
  ) : (
    <a
      href={to}
      className={styles}
      target={newTab ? '_blank' : undefined}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default TextLink;
