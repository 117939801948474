import useAuth from '@app/auth/use-auth';
import Intercom, {
  boot,
  hide,
  show,
  showArticle,
  showNewMessage,
  showSpace,
  shutdown,
  startChecklist,
  startTour,
  update,
} from '@intercom/messenger-js-sdk';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { articles, tours } from './content';
import {
  IntercomContext,
  OpenNewIntercomMessage,
  ShowIntercomArticle,
  ShowIntercomSpace,
  StartIntercomChecklist,
  StartIntercomTour,
  UpdateIntercom,
} from './IntercomContext';

const INTERCOM_APP_ID = 'l22rikuz';

Intercom({
  app_id: INTERCOM_APP_ID,
});

export const IntercomProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const [loaded, setLoaded] = useState<boolean>();

  useEffect(() => {
    if (!loaded && user && !user.isStaff && user.intercomHash) {
      const createdDate = new Date(user.createdAt);
      const createdTime = Math.floor(createdDate.getTime() / 1000);
      boot({
        app_id: INTERCOM_APP_ID,
        user_id: user.uuid,
        user_hash: user.intercomHash,
        name: user.userName,
        email: user.userEmail,
        company: {
          id: user.organizationUuid,
          name: user.organizationName,
          plan: user.plan || user.organizationPlan,
          'Product category': user.productCategory,
          'Has PCF': user.features.pcf,
          'Has CCF': user.features.ccf,
        },
        created_at: createdTime,
      });
      setLoaded(true);
    }
  }, [loaded, user]);

  const showIntercom = useCallback(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const hideIntercom = useCallback(() => {
    if (loaded) {
      hide();
    }
  }, [loaded]);

  const resetIntercom = useCallback(() => {
    if (loaded) {
      shutdown();
      setLoaded(false);
    }
  }, [loaded]);

  const openNewIntercomMessage: OpenNewIntercomMessage = useCallback(
    (content) => {
      if (loaded) {
        showNewMessage(content || '');
      }
    },
    [loaded]
  );

  const showIntercomSpace: ShowIntercomSpace = useCallback(
    (space) => {
      if (loaded) {
        showSpace(space);
      }
    },
    [loaded]
  );

  const showIntercomArticle: ShowIntercomArticle = useCallback(
    (article) => {
      if (loaded) {
        showArticle(articles[article]);
      }
    },
    [loaded]
  );

  const startIntercomTour: StartIntercomTour = useCallback(
    (tour) => {
      if (loaded) {
        startTour(tours[tour]);
      }
    },
    [loaded]
  );

  const startIntercomChecklist: StartIntercomChecklist = useCallback(
    (checklist) => {
      if (loaded) {
        startChecklist(checklist);
      }
    },
    [loaded]
  );

  const updateIntercom: UpdateIntercom = useCallback(
    (data) => {
      if (loaded) {
        update({
          ...data,
          last_request_at: new Date().getTime() / 1000,
        });
      }
    },
    [loaded]
  );

  const value = {
    showIntercom,
    hideIntercom,
    resetIntercom,
    openNewIntercomMessage,
    showIntercomSpace,
    showIntercomArticle,
    startIntercomTour,
    startIntercomChecklist,
    updateIntercom,
  };

  return (
    <IntercomContext.Provider value={value}>
      {children}
    </IntercomContext.Provider>
  );
};
