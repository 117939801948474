export class ApiValidationError extends Error {
  data: string | Record<string, string[]>;

  constructor(message: string, data: string) {
    super(message);
    this.name = 'ApiValidationError';
    this.data = data;
  }
}

export type ApiErrorType = 'activity-already-exists' | 'company-report-overlap';

export interface ApiErrorInterface {
  statusCode: number;
  type: ApiErrorType;
  message: string;
}

export class ApiError extends Error {
  type: ApiErrorType;

  constructor({ type, message }: ApiErrorInterface) {
    super(message);
    this.name = 'ApiError';
    this.type = type;
  }
}
