import useSimpleDialog from '@app/hooks/use-simple-dialog';

import { useGetDirectGasEmissionsActivityList } from '@shared/api/hooks/ccf/activities/direct-gas-emissions';
import {
  CompanyReportsActivitiesDirectGasEmissionsListSortItem,
  CompanyReportsActivitiesDirectGasEmissionsListSourcesItem,
  DirectGasEmissionActivitiesList,
  PaginatedDirectGasEmissionActivitiesListListFilters,
} from '@shared/api/types';
import { SupportButton } from '@shared/components/buttons/support-button';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { baseTableSchema } from '@shared/components/data-table/schema';
import { DataTableFilterField } from '@shared/components/data-table/types';
import { Button } from '@shared/components/ui/button';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useCCF } from '../../ccf-layout';
import DirectGasEditForm from './direct-gas-edit-form';
import { directGasTableColumns } from './direct-gas-table-columns';

export const schema = baseTableSchema.extend({
  uuid: z.string().optional(),
});

export default function DirectGasTable() {
  const { reportUuid } = useCCF();
  const [filters, setFilters] =
    useState<PaginatedDirectGasEmissionActivitiesListListFilters>({});
  const [selectedActivityUuid, setSelectedActivityUuid] =
    useState<DirectGasEmissionActivitiesList['uuid']>();
  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));
  const {
    showSimpleDialog: showDirectGasDialog,
    SimpleDialogComponent: DirectGasDialog,
    closeSimpleDialog: closeDirectGasDialog,
  } = useSimpleDialog();

  const suppliers = searchParams.get('supplier')?.split('.');
  const time_periods = searchParams.get('period')?.split('.');
  const sites = searchParams.get('site')?.split('.');
  const gases = searchParams.get('gas')?.split('.');
  const sources = searchParams.get('source')?.split('.');
  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetDirectGasEmissionsActivityList(reportUuid!, {
      page: search.page,
      page_size: search.page_size,
      suppliers,
      time_periods,
      sites,
      gases,
      sources: sources?.map(
        (source) =>
          source as CompanyReportsActivitiesDirectGasEmissionsListSourcesItem
      ),
      search: search.uuid || '',
      sort: [
        search.sort,
      ] as CompanyReportsActivitiesDirectGasEmissionsListSortItem[],
    });

  if (isError) throw new Error();

  const { results: activities, count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterOptions = useMemo(() => {
    const sites =
      filters.sites?.map((filter) => {
        return {
          label: filter.name,
          value: filter.uuid,
        };
      }) || [];

    const gases =
      filters.gases?.map((filter) => {
        return {
          label: filter.name,
          value: filter.uuid,
        };
      }) || [];

    const sources =
      filters.sources?.map((filter) => {
        return {
          label: capitalizeFirstLetter(filter.name),
          value: filter.name,
        };
      }) || [];

    return {
      sites,
      gases,
      sources,
    };
  }, [filters]);

  useEffect(() => {
    if (data && !isPreviousData && isFetchedAfterMount) {
      setFilters({
        timePeriods: data.filters?.timePeriods,
        sites: data.filters?.sites,
        gases: data.filters?.gases,
        sources: data.filters?.sources,
      });
    }
  }, [data, isFetchedAfterMount, isPreviousData]);

  const filterFields: DataTableFilterField<DirectGasEmissionActivitiesList>[] =
    [
      {
        label: 'Search',
        value: 'uuid',
        placeholder: 'Search sites...',
      },
      {
        label: 'Site',
        value: 'site',
        options: filterOptions.sites,
      },
      {
        label: 'Gas',
        value: 'gas',
        options: filterOptions.gases,
      },
      {
        label: 'Source',
        value: 'source',
        options: filterOptions.sources,
      },
    ];

  const { table } = useServerDataTable({
    data: activities || [],
    columns: directGasTableColumns,
    pageCount,
    filterFields,
    defaultPerPage: 10,
    rowId: 'uuid',
    columnVisibilityState: {
      uuid: false,
      period: false,
    },
  });

  return (
    <>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        onRowClick={(row) => {
          setSelectedActivityUuid(row?.original.uuid);
          showDirectGasDialog();
        }}
        isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
      >
        <DataTableToolbar table={table} filterFields={filterFields}>
          <SupportButton article="directGas" size="sm" />
          <Button
            size="sm"
            onClick={() => {
              setSelectedActivityUuid(undefined);
              showDirectGasDialog();
            }}
          >
            Add data
          </Button>
        </DataTableToolbar>
      </DataTable>
      {DirectGasDialog({
        header: 'Add direct gas data',
        content: (
          <DirectGasEditForm
            activityUuid={selectedActivityUuid}
            closeDialog={closeDirectGasDialog}
          />
        ),
      })}
    </>
  );
}
