import EmptyPageContent from '@app/ccf/components/empty-page-content';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useGetElectricitySupplies } from '@shared/api';
import { ElectricitySupply } from '@shared/api/types';
import { SupportButton } from '@shared/components/buttons/support-button';
import Text from '@shared/components/content/text';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { DataTableFilterField } from '@shared/components/data-table/types';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { electricitySuppliesTableColumns } from './electricity-supplies-table-columns';
import ElectricitySupplyForm from './electricity-supply-form';

export const schema = z.object({
  page: z.coerce.number().default(1),
  page_size: z.coerce.number().optional(),
  sort: z.string().optional(),
  name: z.string().optional(),
});

export default function ElectricitySuppliesTable() {
  const [selectedSupply, setSelectedSupply] = useState<ElectricitySupply>();
  const resetSelectedSupply = () => setSelectedSupply(undefined);
  const { showSimpleDialog, SimpleDialogComponent, closeSimpleDialog } =
    useSimpleDialog(resetSelectedSupply);
  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));

  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetElectricitySupplies({
      page: search.page,
      page_size: search.page_size,
      search: search.name || '',
    });

  if (isError) throw new Error();

  const { results: supplies = [], count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterFields: DataTableFilterField<ElectricitySupply>[] = [
    {
      label: 'Name',
      value: 'name',
      placeholder: 'Search supplies...',
    },
  ];

  const { table } = useServerDataTable({
    data: supplies,
    columns: electricitySuppliesTableColumns,
    pageCount,
    filterFields,
    defaultPerPage: 10,
    rowId: 'uuid',
    columnVisibilityState: {
      uuid: false,
      period: false,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {supplies?.length === 0 ? (
        <EmptyPageContent
          title="Add your electricity supplies"
          description={
            <div className="space-y-4">
              <Text className="text-center">
                By default, we will calculate the emissions for your purchased
                electricity (Scope 2) using average location-based emission
                factors.
              </Text>
              <Text className="text-center">
                If possible, we recommend obtaining the actual emission factor
                for the electricity you purchase, for each of your sites, from
                your electricity provider.
              </Text>
            </div>
          }
          primaryAction={showSimpleDialog}
          primaryActionText="Add electricity supply"
          supportArticle="electricitySupplies"
        />
      ) : (
        <DataTable
          table={table}
          enablePagination
          className="bg-gray-00"
          onRowClick={(row) => {
            setSelectedSupply(row?.original as ElectricitySupply);
            showSimpleDialog();
          }}
          isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
        >
          <DataTableToolbar table={table} filterFields={filterFields}>
            <SupportButton article="electricitySupplies" size="sm" />
            <Button size="sm" onClick={showSimpleDialog}>
              Add supply
            </Button>
          </DataTableToolbar>
        </DataTable>
      )}
      {SimpleDialogComponent({
        header: `${selectedSupply ? 'Edit' : 'Add'} electricity supply`,
        content: (
          <ElectricitySupplyForm
            supply={selectedSupply}
            closeDialog={() => {
              setSelectedSupply(undefined);
              closeSimpleDialog();
            }}
          />
        ),
      })}
    </>
  );
}
