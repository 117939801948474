import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import Text from '@shared/components/content/text';
import { useMemo } from 'react';
import { useCCF } from '../../ccf-layout';
import DirectGasEditForm from '../../components/scope-1/direct-gas-edit-form';
import DirectGasTable from '../../components/scope-1/direct-gas-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function DirectGasEmissionsPage() {
  const { showIntercomArticle } = useIntercom();
  const { summary } = useCCF();
  const {
    showSimpleDialog: showDirectGasDialog,
    SimpleDialogComponent: DirectGasDialog,
    closeSimpleDialog: closeDirectGasDialog,
  } = useSimpleDialog();

  const categoryStatus = useMemo(
    () =>
      summary?.filter((item) => item.slug === 'direct-gas-emissions')[0].status,
    [summary]
  );

  const Description = (
    <>
      <Text>
        Direct gas emissions come from the{' '}
        <span className="font-semibold">use and leakage of specific gases</span>{' '}
        during business operations. These emissions are categorised as Scope 1
        because they are directly produced by your business activities.
      </Text>
      <Text className="mt-3">Examples include:</Text>
      <Text>
        <ul className="list-disc pl-5">
          <li>
            <span className="font-semibold">Leakage of refrigerants</span> like
            HFCs from cooling systems (e.g. air conditioners, chillers,
            freezers).
          </li>
          <li>
            <span className="font-semibold">Leakage of gases</span> like CO₂ and
            N₂O from fire extinguishers, or similar equipment.
          </li>
          <li>
            <span className="font-semibold">
              Methane or nitrous oxide leaks
            </span>{' '}
            from waste management or wastewater treatment.
          </li>
        </ul>
      </Text>
    </>
  );

  return (
    <Page
      title="Scope 1 - Direct gas emissions"
      name="Scope 1 - Direct gas emissions"
    >
      {categoryStatus != 'unstarted' ? (
        <DirectGasTable />
      ) : (
        <EmptyCategoryPage
          categorySlug="direct-gas-emissions"
          description={Description}
          primaryActionProps={{
            onClick: showDirectGasDialog,
          }}
          supportArticle="directGas"
        />
      )}
      {DirectGasDialog({
        header: 'Add direct gas data',
        content: <DirectGasEditForm closeDialog={closeDirectGasDialog} />,
      })}
    </Page>
  );
}
