import Page from '@app/components/layout/page/page';
import Text from '@shared/components/content/text';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../../../ccf-routes';
import { useCCF } from '../../ccf-layout';
import FoodTable from '../../components/scope-3/ccf-food-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function CCFFoodAndDrinkPage() {
  const navigate = useNavigate();
  const { reportUuid, summary } = useCCF();
  const foodStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'food')[0].status,
    [summary]
  );

  return (
    <Page name="Scope 3 - Food and drink">
      {foodStatus != 'unstarted' ? (
        <FoodTable />
      ) : (
        <EmptyCategoryPage
          categorySlug="food"
          description={
            <div className="space-y-4">
              <Text>
                The food and drink your company purchases fall under{' '}
                <b>
                  Scope 3, Category 1 of the GHG Protocol: Purchased Goods &
                  Services
                </b>
                . These emissions are crucial to measure, as they typically
                account for <b>~70%</b> of the total emissions of a food
                business.
              </Text>
              <Text>
                You can source this information directly from your purchase
                orders or supplier invoices, often accessible through your
                procurement or accounting systems.
              </Text>
            </div>
          }
          primaryActionProps={{
            onClick: () =>
              navigate(
                generatePath(ccfRoutes.IMPORT_DOWNLOAD, {
                  report_uuid: reportUuid,
                })
              ),
          }}
          supportArticle="food"
        />
      )}
    </Page>
  );
}
