import { IntercomArticle } from '@app/services/intercom/content';
import { SupportButton } from '@shared/components/buttons/support-button';
import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import { ReactNode } from 'react';

interface EmptyPageContentProps {
  title?: string;
  description: string | ReactNode;
  primaryAction: () => void;
  primaryActionText: string;
  supportArticle: IntercomArticle;
}

export default function EmptyPageContent({
  title,
  description,
  primaryAction,
  primaryActionText,
  supportArticle,
}: EmptyPageContentProps) {
  return (
    <div className="mb-32 flex flex-1 items-center justify-center">
      <div className="flex items-center justify-center">
        <div className="flex max-w-lg flex-col items-center space-y-8">
          {title && <Text variant="section">{title}</Text>}
          {typeof description === 'string' ? (
            <Text className="text-center">{description}</Text>
          ) : (
            description
          )}
          <div className="flex items-center justify-between space-x-4">
            {supportArticle && (
              <SupportButton article={supportArticle} size="lg" />
            )}
            <Button type="button" size="lg" onClick={primaryAction}>
              {primaryActionText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
