import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsActivitiesDirectGasEmissionsListQueryKey,
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsActivitiesDestroy,
  useCompanyReportsActivitiesDirectGasEmissionsCreate,
  useCompanyReportsActivitiesDirectGasEmissionsList,
  useCompanyReportsActivitiesDirectGasEmissionsRetrieve,
  useCompanyReportsActivitiesDirectGasEmissionsUpdate,
} from '@shared/api/lib/company-reports/company-reports';
import {
  CompanyReportsActivitiesDirectGasEmissionsListParams,
  DirectGasEmissionActivitiesList,
  DirectGasEmissionActivityRetrieve,
  PaginatedDirectGasEmissionActivitiesListList,
} from '@shared/api/types';

import { useQueryClient } from '@tanstack/react-query';

export const useGetDirectGasEmissionsActivityList = (
  companyReportUuid: string,
  params?: CompanyReportsActivitiesDirectGasEmissionsListParams
) => {
  return useCompanyReportsActivitiesDirectGasEmissionsList<PaginatedDirectGasEmissionActivitiesListList>(
    companyReportUuid,
    params,
    {
      request: useAxiosConfig(),
      query: {
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    }
  );
};

export const useCreateDirectGasEmissionsActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDirectGasEmissionsCreate<DirectGasEmissionActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesDirectGasEmissionsListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useGetDirectGasEmissionsActivity = ({
  directGasActivityUuid,
  reportUuid,
  params,
}: {
  directGasActivityUuid?: string;
  reportUuid: string;
  params?: CompanyReportsActivitiesDirectGasEmissionsListParams;
}) => {
  return useCompanyReportsActivitiesDirectGasEmissionsRetrieve<DirectGasEmissionActivityRetrieve>(
    reportUuid,
    directGasActivityUuid!,
    params,
    {
      request: useAxiosConfig(),
      query: {
        enabled: !!directGasActivityUuid,
      },
    }
  );
};

export const useUpdateDirectGasEmissionsActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDirectGasEmissionsUpdate<DirectGasEmissionActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesDirectGasEmissionsListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useDeleteDirectGasEmissionsActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(
          getCompanyReportsActivitiesDirectGasEmissionsListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};
