import { ccfRoutes } from '@app/ccf/ccf-routes';
import { useFoodImport } from '@app/ccf/food-import/food-import-layout';
import Page from '@app/components/layout/page/page';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetCompanyReportPeriods } from '@shared/api';
import { useCreateFoodImport } from '@shared/api/hooks/ccf/activities/food';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import { TimePeriodTypeEnum } from '@shared/api/types';
import Text from '@shared/components/content/text';
import CsvImporterFood from '@shared/components/csv-import/food/csv-importer-food';
import {
  FoodImportData,
  FoodImportRow,
} from '@shared/components/csv-import/food/types';
import CsvImporterFoodPrototype from '@shared/components/csv-import/prototype/csv-importer-food-prototype';
import { CleanedFoodImportData } from '@shared/components/csv-import/prototype/types';
import SelectFormField, {
  SelectOption,
} from '@shared/components/form/select-form-field';
import GuideTemplate from '@shared/components/guide-template';
import Loader from '@shared/components/loader';
import SimpleSelect from '@shared/components/simple-select';
import { Form, FormLabel } from '@shared/components/ui/form';
import {
  PurchaseCleanupResponse,
  usePurchaseCleanup,
} from '@shared/reggie/api/usePurchaseCleanup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { StepType } from 'react-spreadsheet-import';
import { object, z } from 'zod';

const periodSchema = object({
  periodUuid: z.string().min(1),
});

interface CleanResponse extends FoodImportRow {
  success: boolean;
  cleanedData?: PurchaseCleanupResponse;
  error?: any;
}

export default function FoodImportPeriodPage() {
  const { mutateAsync: purchaseCleanup } = usePurchaseCleanup();
  const [cleanResults, setCleanResults] = useState<CleanResponse[]>([]);
  const [cleanedDataImportOpen, setCleanedDataImportOpen] = useState(false);

  const [timePeriod, setTimePeriod] = useState<string>(TimePeriodTypeEnum.year);
  const { reportUuid, setImportUuid, reportTitle, setNumberOfActivities } =
    useFoodImport();
  const { data: periods, isLoading: isPeriodsLoading } =
    useGetCompanyReportPeriods(reportUuid);
  const { mutateAsync: createFoodImport } = useCreateFoodImport();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof periodSchema>>({
    resolver: zodResolver(periodSchema),
  });

  const { getValues, setValue, handleSubmit } = form;

  const [importerOpen, setImporterOpen] = useState(false);
  const openImporter = () => {
    setImporterOpen(true);
  };

  const closeImporter = () => setImporterOpen(false);
  const closeCleanedDataImporter = () => setCleanedDataImportOpen(false);

  const { data: units, status } = useGetActivityTypeUnits('food');
  const setUnitUuid = (unit: string) => {
    return units?.find((u) => u.symbol === unit)?.uuid as string;
  };

  const setQuantity = (
    itemQuantity: string,
    itemsPerPack: string,
    quantity: string
  ) => {
    return (
      (parseFloat(itemQuantity) / parseFloat(itemsPerPack)) *
      parseFloat(quantity)
    ).toString() as string;
  };

  const getPeriodOptions = useMemo(() => {
    return periods
      ?.filter((period) => period.type === timePeriod)
      .map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      })) as SelectOption[] | [];
  }, [periods, timePeriod]);

  const startImport = async (rows: CleanedFoodImportData) => {
    const importData = {
      companyReportUuid: reportUuid,
      data: {
        timePeriod: getValues('periodUuid'),
        activities: rows.map((row) => ({
          name: row.name as string,
          quantity: setQuantity(
            row.itemQuantity as string,
            row.itemsPerPack as string,
            row.quantity as string
          ),
          unit: setUnitUuid(row.itemUnit as string),
          supplier: row.supplier as string,
          remoteId: row.remoteId as string,
        })),
      },
    };

    const { uuid: importUuid } = await createFoodImport(importData);
    setNumberOfActivities(rows.length);
    setImportUuid(importUuid);
    navigate(
      generatePath(ccfRoutes.IMPORTING_FOOD, { report_uuid: reportUuid })
    );
  };

  const startCleanup = async (rows: FoodImportData) => {
    try {
      const results = (await Promise.all(
        rows.map(async (row) => {
          try {
            const response = await purchaseCleanup({
              name: row.name as string,
              quantity: row.quantity as any,
              unit: row.unit as string,
            });
            return {
              ...row,
              cleanedData: response,
            };
          } catch (error) {
            return {
              ...row,
              error,
              cleanedData: null,
            };
          }
        })
      )) as CleanResponse[];

      setCleanResults(results);
      setCleanedDataImportOpen(true);
    } catch (error) {
      console.error('Error during cleanup:', error);
    }
  };

  useEffect(() => {
    if (timePeriod == 'year' && getPeriodOptions) {
      setValue('periodUuid', getPeriodOptions[0].value);
    }
  }, [timePeriod, getPeriodOptions, form, setValue]);

  return (
    <Page name={`Food and drink - Import | ${reportTitle}`}>
      <GuideTemplate
        preTitle="Import food and drink purchases"
        title="Upload your spreadsheet"
        description={
          <div className="space-y-4">
            <Text>
              Assign your data to a specific time period. If not organised by
              period, upload it for the full year. You can upload data multiple
              times, even for the same period, so there is no need to do it all
              in one go.
            </Text>
          </div>
        }
        primaryActionProps={{
          disabled: isPeriodsLoading,
          onClick: handleSubmit(openImporter),
        }}
        primaryActionText="Begin import"
        secondaryActionText="Back"
        secondaryActionProps={{
          disabled: isPeriodsLoading,
          onClick: () =>
            navigate(
              generatePath(ccfRoutes.IMPORT_DOWNLOAD, {
                report_uuid: reportUuid,
              })
            ),
        }}
      >
        {periods && (
          <Form {...form}>
            <form onSubmit={handleSubmit(openImporter)} className="text-left">
              <FormLabel>Period</FormLabel>
              <div className="mt-1 flex items-end space-x-2">
                <SimpleSelect
                  options={Object.keys(TimePeriodTypeEnum)}
                  value={timePeriod}
                  onValueChange={(value) => {
                    setTimePeriod(value);
                    setValue('periodUuid', '');
                  }}
                  disabled={isPeriodsLoading}
                  className="w-[180px]"
                />
                <SelectFormField
                  name="periodUuid"
                  className="w-[260px]"
                  options={getPeriodOptions}
                  disabled={isPeriodsLoading}
                />
              </div>
            </form>
          </Form>
        )}
        {isPeriodsLoading && <Loader />}
        <CsvImporterFood
          isOpen={importerOpen}
          onClose={closeImporter}
          handleSubmit={startCleanup}
          prototype
        />
        <CsvImporterFoodPrototype
          isOpen={cleanedDataImportOpen}
          onClose={closeCleanedDataImporter}
          handleSubmit={startImport}
          initialStepState={{
            type: StepType.matchColumns,
            data: [
              ...cleanResults.map((row) => [
                row.remoteId,
                row.supplier,
                row.name,
                row.quantity,
                row.cleanedData?.quantity,
                row.unit,
                row.cleanedData?.itemsPerPurchase,
                row.cleanedData?.itemUnitWeight,
                row.cleanedData?.itemUnit,
                row.cleanedData?.foodType,
              ]),
            ],
            headerValues: [
              'remoteId',
              'supplier',
              'name',
              'quantity',
              'original_quantity',
              'unit',
              'itemsPerPack',
              'itemQuantity',
              'itemUnit',
              'itemType',
            ],
          }}
        />
      </GuideTemplate>
    </Page>
  );
}
