import { CompanyReportSummaryStatusEnum } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Scope3CategoryBadge from '@shared/components/content/scope-3-category-badge';
import Text from '@shared/components/content/text';
import { Card, CardContent } from '@shared/components/ui/card';
import { cn } from '@shared/lib/utils';
import { Link, useNavigate } from 'react-router-dom';
import { Scope3Category } from '../scopes';

interface HubCardProps {
  icon: React.ReactNode;
  name: string;
  scope3Category?: Scope3Category;
  totalEmissions: number | null;
  description: string;
  href: string;
  status?: CompanyReportSummaryStatusEnum;
  statusDescription: string;
  slug: string;
  cardStatus?: 'disabled' | 'excluded' | 'default';
}

const cardDescription: { [key: string]: string } = {
  food: 'Food and drink you have purchased',
};

export default function HubCard({
  icon,
  name,
  scope3Category,
  totalEmissions,
  description,
  href,
  status,
  statusDescription,
  slug,
  cardStatus = 'default',
}: HubCardProps) {
  const navigate = useNavigate();

  return (
    <Link
      to={href}
      onClick={(e) => {
        if (cardStatus === 'disabled') {
          e.preventDefault();
          return;
        }
        navigate(href, {
          state: {
            subTabsActive: href,
          },
        });
      }}
    >
      <Card
        className={cn('shadow-md mb-2', {
          'opacity-50': cardStatus === 'disabled',
        })}
      >
        <CardContent className="flex flex-row items-center pb-2 pt-3">
          <div>{icon}</div>
          <span className="ml-2 text-[16px]">{name}</span>
          <div className="ml-auto flex items-baseline">
            <span
              className={cn('mr-1 text-xl text-teal-600', {
                'text-gray-600': !totalEmissions,
              })}
            >
              {totalEmissions ? totalEmissions.toLocaleString() : '-'}
            </span>
            <Text variant="unit">
              tCO<sub>2</sub>e
            </Text>
          </div>
        </CardContent>
        {scope3Category && (
          <CardContent className="pb-4">
            <Scope3CategoryBadge category={scope3Category} size="sm" />
          </CardContent>
        )}
        <CardContent className="flex justify-between">
          <Text variant="subtle">{cardDescription[slug] || description}</Text>
          <span className="flex items-center">
            {cardStatus == 'disabled' && (
              <>
                <Icons.hourglass className="mr-1" size={14} />
                <p>Coming soon</p>
              </>
            )}
            {cardStatus == 'excluded' && (
              <>
                <Icons.x className="mr-1 text-error" size={14} />
                <Text variant="subtle">Excluded from report</Text>
              </>
            )}
            {cardStatus == 'default' && status === 'unstarted' && (
              <>
                <Icons.minus className="mr-1 text-gray-400" size={14} />
                <Text variant="subtle">No data added</Text>
              </>
            )}
            {cardStatus == 'default' && status === 'started' && (
              <>
                <Icons.loader2 className="mr-1 text-orange-300" size={14} />
                <Text variant="subtle">{statusDescription}</Text>
              </>
            )}
            {cardStatus == 'default' && status === 'completed' && (
              <>
                <Icons.check className="mr-1 text-secondary-400" size={14} />
                <Text variant="subtle">{statusDescription}</Text>
              </>
            )}
          </span>
        </CardContent>
      </Card>
    </Link>
  );
}
