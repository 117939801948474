import { useGetCountries } from '@shared/api';
import { useMemo } from 'react';
import SelectSearchFormField from './select-search-form-field';

interface CountryPickerProps {
  label?: string;
  fieldName?: string;
  pluralName?: string;
  disabled?: boolean;
  className?: string;
  onlyCcfSupported?: boolean;
}

export default function CountryPicker({
  fieldName = 'country',
  label = 'Country',
  disabled,
  className,
  onlyCcfSupported = false,
}: CountryPickerProps) {
  const { data: countries, isLoading: loadingCountries } = useGetCountries(
    onlyCcfSupported ? { ccf_supported: true } : {}
  );

  const formattedCountries = useMemo(() => {
    return countries?.map((country) => ({
      value: country.uuid,
      label: country.name,
    })) as { value: string; label: string }[];
  }, [countries]);

  return (
    <SelectSearchFormField
      name={fieldName}
      pluralName="countries"
      label={label}
      options={formattedCountries || []}
      disabled={loadingCountries || disabled}
      loading={loadingCountries}
      className={className}
    />
  );
}
