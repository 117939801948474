import { ApiError } from '@app/api/errors';
import Page from '@app/components/layout/page/page';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateCompanyReport, useGetSites } from '@shared/api';
import Alert from '@shared/components/alert';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import DatePickerFormField from '@shared/components/form/date-picker-form-field';
import GuideTemplate from '@shared/components/guide-template';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import {
  addWeeks,
  addYears,
  formatISO,
  isAfter,
  isBefore,
  isFuture,
  set,
  subDays,
  subWeeks,
  subYears,
} from 'date-fns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { date, object, z } from 'zod';
import { ccfRoutes } from '../../ccf-routes';

export const reportSetupSchema = object({
  startDate: date({
    required_error: 'Start date is required',
  }),
  endDate: date({
    required_error: 'End date is required',
  }),
});

const getEndDateFromStartDate = (startDate: Date) => {
  return subDays(addYears(startDate, 1), 1);
};

export default function CCFSetupPeriod() {
  const [isStartDateCalenderOpen, setIsStartDateCalenderOpen] = useState(false);
  const [isEndDateCalenderOpen, setIsEndDateCalenderOpen] = useState(false);
  const { showIntercomArticle } = useIntercom();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { mutateAsync: createCompanyReport, isLoading: creatingReport } =
    useCreateCompanyReport();
  const { data: sitesResponse, isLoading: isLoadingSites } = useGetSites({});
  const hasSites =
    !isLoadingSites &&
    sitesResponse?.results &&
    sitesResponse.results.length > 0;

  const form = useForm<z.infer<typeof reportSetupSchema>>({
    resolver: zodResolver(reportSetupSchema),
  });

  const { getValues } = form;

  const handleStartDateChange = () => {
    const startDate = getValues('startDate');
    const endDate = getEndDateFromStartDate(startDate);
    setIsStartDateCalenderOpen(false);
    form.setValue('endDate', endDate);
  };

  const handleEndDateChange = () => {
    form.setValue('endDate', getValues('endDate'));
    setIsEndDateCalenderOpen(false);
  };

  const onSubmit = async (data: z.infer<typeof reportSetupSchema>) => {
    try {
      const { uuid } = await createCompanyReport({
        data: {
          startDate: formatISO(data.startDate, { representation: 'date' }),
          endDate: formatISO(data.endDate, { representation: 'date' }),
        },
      });

      if (hasSites) {
        navigate(
          generatePath(ccfRoutes.COMPANY_REPORT, {
            report_uuid: uuid,
          })
        );
      } else {
        navigate(
          generatePath(ccfRoutes.REPORT_SETUP_SITES, { report_uuid: uuid })
        );
      }
    } catch (error) {
      sentry.log(error);

      let description =
        'Please try again or get in touch if the error persists';

      if (
        error instanceof ApiError &&
        error.type === 'company-report-overlap'
      ) {
        description = 'This reporting period overlaps with an existing report';
      }

      toast({
        title: 'Error creating your report',
        variant: 'destructive',
        description,
      });
    }
  };

  return (
    <Page name="Report setup">
      <GuideTemplate
        title="Your reporting period"
        description={
          <div>
            <Text>
              Your reporting period must cover one full year, ensuring
              consistency with financial and regulatory standards. Need help
              choosing your reporting period?{' '}
              <TextLink onClick={() => showIntercomArticle('reportingPeriod')}>
                Read our guide
              </TextLink>
            </Text>
          </div>
        }
        primaryActionProps={{
          disabled: creatingReport,
          loading: creatingReport,
          onClick: () => form.handleSubmit(onSubmit)(),
        }}
        secondaryActionProps={{
          disabled: creatingReport,
          onClick: () => navigate(generatePath('/company-reports/welcome')),
        }}
        primaryActionText="Set period"
        secondaryActionText="Go back"
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="min-w-[240px]"
          >
            <DatePickerFormField
              name="startDate"
              label="Start date"
              includeErrorMessage
              className="mb-6"
              handleSelect={handleStartDateChange}
              defaultMonth={
                form.getValues('startDate') ||
                set(subYears(new Date(), 1), {
                  date: 1,
                  month: 0,
                })
              }
              disabledDates={isFuture}
              isCalenderOpen={isStartDateCalenderOpen}
              setIsCalenderOpen={setIsStartDateCalenderOpen}
              fromYear={2000}
              toYear={new Date().getFullYear()}
            />
            <DatePickerFormField
              name="endDate"
              label="End date"
              placeholder="Select a start date"
              handleSelect={handleEndDateChange}
              defaultMonth={getValues('endDate')}
              disabledDates={(date) => {
                const endDate = getEndDateFromStartDate(getValues('startDate'));
                return (
                  isBefore(date, subWeeks(endDate, 1)) ||
                  isAfter(date, addWeeks(endDate, 1))
                );
              }}
              disabled={
                !getValues('startDate') || getValues('startDate') === null
              }
              isCalenderOpen={isEndDateCalenderOpen}
              setIsCalenderOpen={setIsEndDateCalenderOpen}
              required
            />
          </form>
          <Alert variant="warning" className="mt-6 py-2">
            <div className="flex flex-row items-center space-x-2 text-warning-900">
              <Icons.warning className="size-4" />
              <Text className="text-sm ">
                Once set, your reporting period cannot be changed
              </Text>
            </div>
          </Alert>
        </Form>
      </GuideTemplate>
    </Page>
  );
}
