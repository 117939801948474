import { useGetCompanyReportPeriods } from '@shared/api';
import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import InputFormField from '@shared/components/form/input-form-field';
import SelectFormField from '@shared/components/form/select-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { useEffect } from 'react';
import { useCCF } from '../../ccf-layout';
import { useGetPeriodOptions, useUnitOptions } from '../../utils';

interface StationaryReadingRowProps extends RepeatableRowComponentProps {}

export default function StationaryReadingRow({
  index,
  setDisableAdd,
}: StationaryReadingRowProps) {
  const { reportUuid } = useCCF();
  const { data: units } = useGetActivityTypeUnits('stationary-combustion');
  const { data: periodOptions } = useGetCompanyReportPeriods(reportUuid!);

  useEffect(() => {
    setDisableAdd(true);
  });

  return (
    <div className="grid grid-cols-12 items-start gap-2">
      <SelectFormField
        name={`records.${index}.timePeriod`}
        options={useGetPeriodOptions(periodOptions!)}
        disabled
        label={index === 0 ? 'Period' : ''}
        className="col-span-4"
      />
      <InputFormField
        name={`records.${index}.quantity`}
        label={index === 0 ? 'Quantity' : ''}
        className="col-span-4"
        type="number"
        includeErrorMessage
      />
      <SelectFormField
        name="unit"
        options={useUnitOptions(units!)}
        label={index === 0 ? 'Unit' : ''}
        className="col-span-4"
        useShortLabel
        disabled
      />
    </div>
  );
}
