import { useGetActivityTypeUnits } from '@shared/api/hooks/ccf/units';
import sentry from '@shared/services/sentry';
import { FC, useMemo } from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { Result } from 'react-spreadsheet-import/types/types';
import { getCleanupFoodFields } from '../prototype/fields';
import { useImporterTheme } from '../useImporterTheme';
import { useImporterTranslations } from '../useImporterTranslations';
import { getFoodFields } from './fields';
import { FoodImportData, FoodImportRow } from './types';
import { validateFoodRow } from './utils';

interface CsvImporterFoodProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (rows: FoodImportData) => Promise<void>;
  // Temporary prop for the prototype
  prototype?: boolean;
}

const CsvImporterFood: FC<CsvImporterFoodProps> = ({
  isOpen,
  onClose,
  handleSubmit,
  prototype = false,
}: CsvImporterFoodProps) => {
  const theme = useImporterTheme();
  const translations = useImporterTranslations();
  const { data: units, status } = useGetActivityTypeUnits('food');
  const unitOptions = useMemo(
    () =>
      units?.map((unit) => ({
        value: unit.uuid,
        label: unit.name,
      })),
    [units]
  );

  const onSubmit = async (data: Result<keyof FoodImportRow>) => {
    try {
      await handleSubmit(data.validData);
    } catch (error) {
      sentry.log(error);
      throw new Error('Failed importing food data, please try again');
    }
  };

  if (status === 'loading') {
    return null;
  }

  if (!unitOptions) {
    throw new Error('Failed to load food units');
  }

  return (
    <ReactSpreadsheetImport<keyof FoodImportRow>
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={prototype ? getCleanupFoodFields() : getFoodFields(unitOptions)}
      autoMapSelectValues
      allowInvalidSubmit={false}
      rowHook={validateFoodRow}
      customTheme={theme}
      translations={translations}
    />
  );
};

export default CsvImporterFood;
