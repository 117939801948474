import { cn } from '@shared/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';
import { ReactNode } from 'react';
import Logo from './logo/logo';

const LoaderVariants = cva('animate-spin', {
  variants: {
    size: {
      xs: 'size-4 border',
      sm: 'size-6 border-2',
      md: 'size-10 border-4',
      lg: 'size-14 border-[5px]',
    },
    color: {
      primary: 'border-gray-300 border-t-primary text-primary-400',
      teal: 'border-teal-300 border-t-teal text-teal-400',
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'teal',
  },
});

interface LoaderProps extends VariantProps<typeof LoaderVariants> {
  className?: ClassValue;
  children?: ReactNode;
  withLogo?: boolean;
}

const Loader = ({
  className,
  size,
  color,
  children,
  withLogo,
}: LoaderProps) => {
  return (
    <div
      className={cn(
        'flex size-full flex-col items-center justify-center gap-4',
        className
      )}
    >
      {withLogo && <Logo className="absolute size-6" />}
      <div
        className={cn(
          LoaderVariants({ size, color }),
          'flex animate-spin items-center justify-center rounded-full text-4xl'
        )}
      >
        {children}
      </div>
    </div>
  );
};
export default Loader;
