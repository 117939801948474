import { useIntercom } from '@app/services/intercom/IntercomContext';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@shared/components/ui/popover';

interface FoodImportTablePopoverProps {
  section: 'remoteId' | 'name' | 'unit';
}

export default function FoodImportTablePopover({
  section,
}: FoodImportTablePopoverProps) {
  const { showIntercomArticle } = useIntercom();
  const info = {
    name: {
      title: 'Name',
      description: (
        <div className="space-y-2">
          <Text>
            This should be the name/description of the food or drink item in
            your system.
          </Text>
          <Text>
            We use this name to automatically match the item with a food from
            our database. You can update the matched foods later, but we
            recommend ensuring these names are fairly descriptive to increase
            the accuracy of your matches.
          </Text>
          <div className="mt-2">
            <TextLink
              className="mt-10 text-sm font-bold text-teal-600"
              onClick={() => showIntercomArticle('foodMatching')}
            >
              Learn more about our food matching
            </TextLink>
          </div>
        </div>
      ),
    },
    remoteId: {
      title: 'Your item ID',
      description: (
        <Text>
          This might be a SKU or an ID from your system. This is optional and
          only for your benefit
        </Text>
      ),
    },

    unit: {
      title: 'Units',
      description: (
        <Text>
          We will ask you to convert your data into our standard units.
          Preparing this ahead of time can help reduce workload during import.
        </Text>
      ),
    },
  };
  return (
    <Popover>
      <PopoverTrigger>
        <Icons.info size={16} className="text-teal-700" />
      </PopoverTrigger>
      <PopoverContent
        side="top"
        className="overflow-hidden rounded-xl border-none p-0"
      >
        <Text className=" bg-teal-600 px-4 py-3 text-gray-00">
          {info[section].title}
        </Text>
        <div className="space-y-2 px-4 py-3">
          <div className="mb-2">{info[section].description}</div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
