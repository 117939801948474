import {
  ActivityUnit,
  StationaryCombustionActivitiesList,
} from '@shared/api/types';
import Unit from '@shared/components/content/unit';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { TextCell } from '@shared/components/data-table/cells/text-cell';
import { ColumnDef } from '@tanstack/react-table';

export const stationaryCombustionTableColumns: ColumnDef<StationaryCombustionActivitiesList>[] =
  [
    {
      accessorKey: 'uuid',
      enableHiding: true,
    },
    {
      accessorKey: 'site',
      header: ({ column }) => <HeaderCell column={column} name="Site" />,
      cell: ({ getValue }) => {
        const site = getValue<StationaryCombustionActivitiesList['site']>();
        return <TextCell text={site.name} />;
      },
    },
    {
      accessorKey: 'fuel',
      header: ({ column }) => <HeaderCell column={column} name="Fuel" />,
      cell: ({ getValue }) => {
        const fuel = getValue<StationaryCombustionActivitiesList['fuel']>();
        return <TextCell text={fuel.name} />;
      },
    },

    {
      accessorKey: 'emissionFactor',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Emission Factor"
          numberColumn
          unit={<Unit variant="kgCO2e/unit" />}
        />
      ),
      cell: ({ getValue }) => {
        const emissionFactor = getValue<number>();
        return <NumberCell number={emissionFactor} rounded />;
      },
    },
    {
      accessorKey: 'quantity',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Quantity"
          numberColumn
          unit={<Unit variant="units" />}
        />
      ),
      cell: ({ getValue }) => {
        const quantity = getValue<number>();
        return <NumberCell number={quantity} rounded />;
      },
    },
    {
      accessorKey: 'unit',
      header: ({ column }) => <HeaderCell column={column} name="Unit" />,
      cell: ({ getValue }) => {
        const unit = getValue<ActivityUnit>();
        return <TextCell text={unit.symbol} />;
      },
    },
    {
      accessorKey: 'emissions',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Emissions"
          numberColumn
          unit={<Unit variant="tCO2e" />}
        />
      ),
      cell: ({ getValue }) => {
        return <NumberCell number={getValue<number>()} rounded />;
      },
    },
  ];
