import { useIntercom } from '@app/services/intercom/IntercomContext';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateElectricitySupply,
  useUpdateElectricitySupply,
} from '@shared/api';
import { ElectricitySupply } from '@shared/api/types';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import CheckboxFormField from '@shared/components/form/checkbox-form-field';
import CountryPicker from '@shared/components/form/country-picker';
import DatePickerFormField from '@shared/components/form/date-picker-form-field';
import InputFormField from '@shared/components/form/input-form-field';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { formatISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { boolean, date, object, string, z } from 'zod';

const supplyFormSchema = object({
  name: string({
    required_error: 'Please provide a name',
  }).min(1),
  country: string({
    required_error: 'Please select a country',
  }),
  validFrom: date({
    required_error: 'Please provide the valid from date',
  }),
  validTo: date({
    required_error: 'Please provide the valid to date',
  }),
  emissionFactor: string(),
  hasCertificate: boolean({
    required_error:
      'You must confirm the emission factor meets our quality criteria',
  }).refine((value) => value === true, {
    message: 'You must confirm the emission factor meets our quality criteria',
  }),
});

interface ElectricitySupplyFormProps {
  supply?: ElectricitySupply;
  closeDialog: () => void;
}

export default function ElectricitySupplyForm({
  supply,
  closeDialog,
}: ElectricitySupplyFormProps) {
  const editing = !!supply;
  const { toast } = useToast();
  const { showIntercomArticle } = useIntercom();

  const { mutateAsync: createSupply, isLoading: creatingSupply } =
    useCreateElectricitySupply();
  const { mutateAsync: updateSupply, isLoading: updatingSupply } =
    useUpdateElectricitySupply();

  const form = useForm<z.infer<typeof supplyFormSchema>>({
    resolver: zodResolver(supplyFormSchema),
    defaultValues: {
      ...supply,
      validFrom: supply?.validFrom ? new Date(supply.validFrom) : undefined,
      validTo: supply?.validTo ? new Date(supply.validTo) : undefined,
      emissionFactor: supply?.emissionFactor?.toString(),
    },
  });

  const onSubmit = async (formData: z.infer<typeof supplyFormSchema>) => {
    try {
      const data = {
        ...formData,
        validFrom: formatISO(formData.validFrom, { representation: 'date' }),
        validTo: formatISO(formData.validTo, { representation: 'date' }),
        emissionFactor: parseFloat(formData.emissionFactor),
      };
      if (editing) {
        await updateSupply({
          uuid: supply.uuid,
          data,
        });
      } else {
        await createSupply({ data });
      }
      toast({
        title: `Supply ${editing ? 'updated' : 'created'}`,
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: `Failed to ${editing ? 'update' : 'create'} supply`,
        variant: 'destructive',
      });
    }
  };

  const loading = creatingSupply || updatingSupply;

  return (
    <Form {...form}>
      <form className="mt-2 space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12 gap-2">
          <InputFormField
            name="name"
            label="Name"
            className="col-span-5"
            autoComplete="false"
            placeholder="Green Energy Co."
          />
          <CountryPicker
            className="col-span-5"
            disabled={editing}
            onlyCcfSupported
          />
        </div>
        <div className="grid grid-cols-12 gap-2">
          <DatePickerFormField
            name="validFrom"
            label="Valid from"
            includeErrorMessage
            className="col-span-5"
            disabled={editing}
          />
          <DatePickerFormField
            name="validTo"
            label="Valid until"
            includeErrorMessage
            className="col-span-5"
            disabled={editing}
          />
        </div>
        <div className="grid grid-cols-12 gap-2">
          <InputFormField
            name="emissionFactor"
            label="Emission factor"
            type="number"
            step="0.0001"
            className="col-span-4"
            placeholder="0.20"
            min={0}
            inputClassName="text-right"
            unit="kgCO2e/kWh"
            disabled={editing}
          />
        </div>
        <div className="space-y-2">
          <Text variant="subtle">
            Please review our quality criteria for providing your own emission
            factors, at the bottom of{' '}
            <TextLink
              onClick={() => showIntercomArticle('electricitySupplies')}
            >
              this article
            </TextLink>
            .
          </Text>
          <CheckboxFormField
            name="hasCertificate"
            disabled={editing}
            label={
              <Text className="font-normal leading-5">
                I confirm that the information provided is accurate and meets
                the required quality criteria
              </Text>
            }
            includeErrorMessage
          />
        </div>
        <div className="flex justify-between">
          <div />
          <div className="flex space-x-2 pt-4">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  form.reset();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button
                type="submit"
                size="lg"
                loading={loading}
                disabled={loading}
              >
                {editing ? 'Update supply' : 'Create supply'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
