import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { PageTabs, Tab } from '@shared/components/page-tabs';
import { Outlet } from 'react-router-dom';

const SitesLayout: React.FC = () => {
  const tabs: Tab[] = [
    {
      title: 'Sites',
      href: '/sites',
    },
    {
      title: 'Electricity Supplies',
      href: '/sites/electricity-supplies',
    },
  ];

  return (
    <Page name="Sites" page="layout" flexFullHeight>
      <PageHeader
        name="Sites"
        description="Manage the sites for your company reports"
        tabs={<PageTabs tabs={tabs} />}
        includeBreadcrumb={false}
      />
      <Outlet />
    </Page>
  );
};

export default SitesLayout;
