/** @type {import('tailwindcss').Config} */
import tailwindTypography from '@tailwindcss/typography';
import { Config } from 'tailwind-merge';
import tailwindAnimate from 'tailwindcss-animate';

export default {
  darkMode: ['class'],
  content: [
    './admin/**/*.{html,js,ts,jsx,tsx}',
    './shared/**/*.{html,js,ts,jsx,tsx}',
    './app/**/*.{html,js,ts,jsx,tsx}',
    './src/**/*.{html,js,ts,jsx,tsx}',
    './index.html',
  ],
  theme: {
    container: {
      center: 'true',
      padding: '2rem',
      screens: {
        '2xl': '1400px',
      },
    },
    colors: {
      primary: {
        '50': '#f0f4f7',
        '100': '#e4e8ec',
        '200': '#d9dfe4',
        '300': '#b4bfc9',
        '400': '#90a1af',
        '500': '#6d8395',
        '600': '#4c677c',
        '700': '#2b4b64',
        '800': '#08314c',
        '900': '#031b2d',
        '950': '#010811',
        DEFAULT: 'var(--primary)',
      },
      gray: {
        '25': '#f8f8f8',
        '50': '#f0f2f3',
        '100': '#e4e7e9',
        '200': '#c9cfd4',
        '300': '#b0b7bf',
        '400': '#96a0aa',
        '500': '#727f8c',
        '600': '#626e79',
        '700': '#48515a',
        '800': '#2e343a',
        '900': '#161a1e',
        '950': '#030405',
        '1000': '#000000',
        '00': '#ffffff',
        DEFAULT: 'var(--gray)',
      },
      secondary: {
        '50': '#f0f8ee',
        '100': '#e4f2df',
        '200': '#cae4c0',
        '300': '#afd6a1',
        '400': '#95c881',
        '500': '#6cb34e',
        '600': '#5e9e44',
        '700': '#447430',
        '800': '#2b4d1d',
        '900': '#14290c',
        '950': '#030902',
        DEFAULT: 'var(--secondary)',
      },
      warning: {
        '50': '#FFFAEE',
        '100': '#FFF6DF',
        '200': '#FFECBF',
        '300': '#FFE29C',
        '400': '#FFD876',
        '500': '#FFC817',
        '600': '#E1B013',
        '700': '#A7820B',
        '800': '#705605',
        '900': '#3E2E01',
        '950': '#120B00',
        DEFAULT: 'var(--warning)',
      },
      error: {
        '50': '#FBE9E8',
        '100': '#F7D8D5',
        '200': '#EDB1AD',
        '300': '#E08A85',
        '400': '#D1625F',
        '500': '#B50A24',
        '600': '#9F081E',
        '700': '#750414',
        '800': '#4E0209',
        '900': '#290003',
        '950': '#090000',
        DEFAULT: 'var(--destructive)',
      },
      teal: {
        '50': '#EDF4F3',
        '100': '#DEEBE9',
        '200': '#BDD7D3',
        '300': '#9CC4BE',
        '400': '#7AB1A9',
        '500': '#44948B',
        '600': '#3B827A',
        '700': '#295F59',
        '800': '#183E3A',
        '900': '#09201D',
        '950': '#010605',
        DEFAULT: 'var(--teal)',
      },
      orange: {
        '50': '#FFF2EB',
        '100': '#FFE7DA',
        '200': '#FFCEB5',
        '300': '#FFB48F',
        '400': '#FF9A67',
        '500': '#FF6E0A',
        '600': '#E16008',
        '700': '#A74504',
        '800': '#702C02',
        '900': '#3E1500',
        '950': '#120300',
        DEFAULT: 'var(--orange)',
      },
    },
    extend: {
      fontFamily: {
        heading: ['TWKBurns, Tahoma, Arial, Helvetica, sans-serif'],
        sans: ['Inter', 'Roboto', 'system-ui', 'sans-serif'],
      },
      fontSize: {
        base: '14px',
      },
      fontWeight: {
        hairline: '50',
        ultra: '950',
      },
      height: {
        nav: '76px',
      },
      maxWidth: {
        'content-center': '540px',
        input: '330px',
        form: '600px',
        'recipe-form-field': '532px',
      },
      colors: {
        border: 'var(--border)',
        input: 'var(--input)',
        ring: 'var(--ring)',
        background: 'var(--background)',
        foreground: 'var(--foreground)',
        primary: {
          DEFAULT: 'var(--primary)',
          foreground: 'var(--primary-foreground)',
        },
        secondary: {
          DEFAULT: 'var(--secondary)',
          foreground: 'var(--secondary-foreground)',
        },
        destructive: {
          DEFAULT: 'var(--destructive)',
          foreground: 'var(--destructive-foreground)',
        },
        muted: {
          DEFAULT: 'var(--muted)',
          foreground: 'var(--muted-foreground)',
        },
        accent: {
          DEFAULT: 'var(--accent)',
          foreground: 'var(--accent-foreground)',
        },
        popover: {
          DEFAULT: 'var(--popover)',
          foreground: 'var(--popover-foreground)',
        },
        card: {
          DEFAULT: 'var(--card)',
          foreground: 'var(--card-foreground)',
        },
        ratingA: {
          DEFAULT: 'var(--rating-a)',
        },
        ratingB: {
          DEFAULT: 'var(--rating-b)',
        },
        ratingC: {
          DEFAULT: 'var(--rating-c)',
        },
        ratingD: {
          DEFAULT: 'var(--rating-d)',
        },
        ratingE: {
          DEFAULT: 'var(--rating-e)',
        },
        farming: {
          light: 'var(--farming-light)',
          DEFAULT: 'var(--farming-default)',
        },
        processing: {
          light: 'var(--processing-light)',
          DEFAULT: 'var(--processing-default)',
        },
        packaging: {
          light: 'var(--packaging-light)',
          DEFAULT: 'var(--packaging-default)',
        },
        transport: {
          light: 'var(--transport-light)',
          DEFAULT: 'var(--transport-default)',
        },
      },
      borderRadius: {
        lg: 'var(--radius)',
        md: 'calc(var(--radius) - 2px)',
        sm: 'calc(var(--radius) - 4px)',
      },
      keyframes: {
        'accordion-down': {
          from: {
            height: '0',
          },
          to: {
            height: 'var(--radix-accordion-content-height)',
          },
        },
        'accordion-up': {
          from: {
            height: 'var(--radix-accordion-content-height)',
          },
          to: {
            height: '0',
          },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
    },
  },
  plugins: [tailwindAnimate, tailwindTypography],
} satisfies Config;
