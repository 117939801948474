import { IntercomArticle } from '@app/services/intercom/content';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { Icons } from '../content/icons';
import { Button, ButtonProps } from '../ui/button';

interface SupportButtonProps extends ButtonProps {
  article: IntercomArticle;
}

export function SupportButton({ article, ...buttonProps }: SupportButtonProps) {
  const { showIntercomArticle } = useIntercom();
  return (
    <Button
      icon={<Icons.info />}
      variant="support"
      onClick={() => showIntercomArticle(article)}
      {...buttonProps}
    >
      Learn more
    </Button>
  );
}
