import { ElectricitySupply } from '@shared/api/types';
import Unit from '@shared/components/content/unit';
import { CountryCell } from '@shared/components/data-table/cells/country-cell';
import { DateCell } from '@shared/components/data-table/cells/date-cell';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { TextCell } from '@shared/components/data-table/cells/text-cell';
import { ColumnDef } from '@tanstack/react-table';

export const electricitySuppliesTableColumns: ColumnDef<ElectricitySupply>[] = [
  {
    accessorKey: 'uuid',
    enableHiding: true,
  },
  {
    accessorKey: 'name',
    enableSorting: false,
    header: ({ column }) => <HeaderCell column={column} name="Name" />,
    cell: ({ getValue }) => <TextCell text={getValue<string>()} />,
  },
  {
    accessorKey: 'country',
    enableSorting: false,
    header: ({ column }) => <HeaderCell column={column} name="Country" />,
    cell: ({ getValue }) => <CountryCell uuid={getValue<string>()} />,
  },
  {
    accessorKey: 'validFrom',
    enableSorting: false,
    header: ({ column }) => <HeaderCell column={column} name="Valid from" />,
    cell: ({ getValue }) => <DateCell date={getValue<string>()} />,
  },
  {
    accessorKey: 'validTo',
    enableSorting: false,
    header: ({ column }) => <HeaderCell column={column} name="Valid until" />,
    cell: ({ getValue }) => <DateCell date={getValue<string>()} />,
  },
  {
    accessorKey: 'emissionFactor',
    enableSorting: false,
    header: ({ column }) => (
      <HeaderCell
        column={column}
        name="Emission factor"
        unit={<Unit variant="kgCO2e/kWh" />}
      />
    ),
    cell: ({ getValue }) => <NumberCell number={getValue<number>()} rounded />,
  },
];
