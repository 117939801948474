import {
  ApiError,
  ApiErrorInterface,
  ApiValidationError,
} from '@app/api/errors';
import axios, { AxiosResponse } from 'axios';

const isApiError = (error: any): error is ApiErrorInterface => {
  return (
    error &&
    typeof error.statusCode === 'string' &&
    typeof error.type === 'string' &&
    typeof error.message === 'string'
  );
};

const handleError = (response: AxiosResponse) => {
  if (response.data && isApiError(response.data)) {
    return new ApiError(response.data);
  }

  if (response.status === 400) {
    const message: string = response.data[0];
    return new ApiValidationError(message, response.data);
  }
};

const authClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
  withCredentials: true,
});

authClient.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const response: AxiosResponse = error.response;
    const handledError = handleError(response);
    return Promise.reject(handledError || error);
  }
);

export { authClient };
