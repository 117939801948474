import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import Text from '@shared/components/content/text';
import { useMemo } from 'react';
import { useCCF } from '../../ccf-layout';
import StationaryCombustionEditForm from '../../components/scope-1/stationary-combustion-edit-form';
import StationaryCombustionTable from '../../components/scope-1/stationary-combustion-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function StationaryCombustionPage() {
  const { showIntercomArticle } = useIntercom();
  const { summary } = useCCF();
  const {
    showSimpleDialog: showStationaryCombustionDialog,
    SimpleDialogComponent: StationaryCombustionDialog,
    closeSimpleDialog: closeStationaryCombustionDialog,
  } = useSimpleDialog();

  const categoryStatus = useMemo(
    () =>
      summary?.filter((item) => item.slug === 'stationary-combustion')[0]
        .status,
    [summary]
  );

  const description = (
    <>
      <Text>
        Stationary combustion refers to the{' '}
        <span className="font-semibold">mains gas supply</span> for your sites,
        as well as any{' '}
        <span className="font-semibold">additional fuels you burn on site</span>
        , such as natural gas, diesel, or propane. These activities produce
        direct emissions and fall under Scope 1 of your company carbon
        footprint.
      </Text>
      <Text className="mt-3">Examples include:</Text>
      <Text>
        <ul className="list-disc pl-5">
          <li>
            <span className="font-semibold">Heating buildings</span>
          </li>
          <li>
            <span className="font-semibold">Running boilers</span>
          </li>
          <li>
            <span className="font-semibold">Powering generators</span>
          </li>
        </ul>
      </Text>
    </>
  );

  return (
    <Page name="Scope 1 - Stationary combustion">
      {categoryStatus != 'unstarted' ? (
        <StationaryCombustionTable />
      ) : (
        <EmptyCategoryPage
          categorySlug="stationary-combustion"
          description={description}
          primaryActionProps={{
            onClick: showStationaryCombustionDialog,
          }}
          supportArticle="stationaryCombustion"
        />
      )}
      {StationaryCombustionDialog({
        header: 'Add stationary combustion data',
        content: (
          <StationaryCombustionEditForm
            closeDialog={closeStationaryCombustionDialog}
          />
        ),
      })}
    </Page>
  );
}
