import { ErrorBoundary } from '@sentry/react';
import Loader from '@shared/components/loader';
import { cn } from '@shared/lib/utils';
import { QueryStatus } from '@tanstack/react-query';
import { FC, ReactNode, useEffect } from 'react';
import { useAnalytics } from '../../../services/analytics/AnalyticsContext';
import PageErrorFallback from './components/page-error-fallback';

interface PageProps {
  name: string;
  title?: string;
  children: ReactNode;
  status?: QueryStatus;
  fallbackComponent?: JSX.Element;
  page?: 'layout' | 'page' | 'subpage';
  flexFullHeight?: boolean;
  className?: string;
}

const Page: FC<PageProps> = ({
  name,
  title,
  children,
  status,
  fallbackComponent,
  className,
  flexFullHeight,
  page = 'page',
}) => {
  const { trackPage } = useAnalytics();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    page === 'page' && trackPage(name);
  }, [name, trackPage, page]);

  useEffect(() => {
    if (page === 'page') {
      document.title = `${title || name} | My Emissions`;
    }
    return () => {
      document.title = 'My Emissions';
    };
  }, [title, name, page]);

  return (
    <div
      className={cn(
        'min-w-full',
        { 'h-full': page === 'layout', 'flex flex-col flex-1': flexFullHeight },
        className
      )}
    >
      <ErrorBoundary fallback={fallbackComponent || <PageErrorFallback />}>
        {status === 'loading' && <Loader />}
        {status === 'error' && (fallbackComponent || <PageErrorFallback />)}
        {status === 'success' && <>{children}</>}
        {!status && children}
      </ErrorBoundary>
    </div>
  );
};

export default Page;
