import Text from '@shared/components/content/text';
import { Button, ButtonProps } from '@shared/components/ui/button';
import { cn } from '@shared/lib/utils';
import { ReactNode } from 'react';

interface GuideTemplateProps {
  preTitle?: string;
  title: string | ReactNode;
  description: string | ReactNode;
  children: ReactNode;
  primaryActionText: string | ReactNode;
  primaryActionProps?: ButtonProps;
  secondaryActionText?: string | ReactNode;
  secondaryActionProps?: ButtonProps;
  className?: string;
}

export default function GuideTemplate({
  preTitle = 'Getting started',
  title,
  description,
  children,
  className,
  primaryActionText,
  primaryActionProps,
  secondaryActionText,
  secondaryActionProps,
}: GuideTemplateProps) {
  return (
    <div
      className={cn(
        'flex size-full  items-center justify-center p-2 md:p-0',
        className
      )}
    >
      <div className="flex min-h-[586px] flex-col items-center text-center">
        <Text
          className={cn('mb-4 uppercase font-bold', {
            'opacity-0': !preTitle,
          })}
        >
          {preTitle}
        </Text>
        {typeof title === 'string' ? (
          <Text
            variant="heroValue"
            className="mb-12 font-heading font-semibold"
          >
            {title}
          </Text>
        ) : (
          <div>{title}</div>
        )}

        {typeof description === 'string' ? (
          <Text className="mb-6 max-w-xl text-center">{description}</Text>
        ) : (
          <div className="mb-6 max-w-xl">{description}</div>
        )}
        <div className="max-w-5xl">{children}</div>
        <div className="mx-auto mt-12 flex w-full max-w-lg justify-around">
          {secondaryActionText && (
            <Button
              variant="secondary"
              className="border border-primary-700 text-primary-700"
              {...secondaryActionProps}
            >
              {secondaryActionText}
            </Button>
          )}

          <Button className="bg-primary-700" {...primaryActionProps}>
            {primaryActionText}
          </Button>
        </div>
      </div>
    </div>
  );
}
