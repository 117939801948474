import { Icons } from '@shared/components/content/icons';
import Logo, { LogoProps } from '@shared/components/logo/logo';
import { Button, ButtonProps } from '@shared/components/ui/button';

interface NavbarProps {
  logo?: LogoProps;
  includeCloseButton?: boolean;
  closeButtonProps?: ButtonProps;
}

export default function Navbar({
  logo = {
    variant: 'name',
    color: 'light',
  },
  includeCloseButton = false,
  closeButtonProps,
}: NavbarProps) {
  return (
    <div className="flex h-nav max-h-min items-center justify-between p-4">
      <Logo variant={logo.variant} color={logo.color} className="w-56" />
      {includeCloseButton && (
        <Button
          variant="ghost"
          className="text-primary-700"
          {...closeButtonProps}
        >
          <Icons.x className="size-4" />
        </Button>
      )}
    </div>
  );
}
