import { UserArgs } from '@intercom/messenger-js-sdk/types';
import { createContext, useContext } from 'react';
import { IntercomArticle, IntercomChecklist, IntercomTour } from './content';

type IntercomSpace =
  | 'home'
  | 'messages'
  | 'help'
  | 'news'
  | 'tasks'
  | 'tickets';

export type ShowIntercomSpace = (space: IntercomSpace) => void;

export type OpenNewIntercomMessage = (content?: string) => void;

export type ShowIntercomArticle = (article: IntercomArticle) => void;

export type StartIntercomTour = (tour: IntercomTour) => void;

export type StartIntercomChecklist = (tour: IntercomChecklist) => void;

export type UpdateIntercom = (data: UserArgs) => void;

export type IntercomContextType = {
  showIntercom: () => void;
  hideIntercom: () => void;
  resetIntercom: () => void;
  openNewIntercomMessage: OpenNewIntercomMessage;
  showIntercomSpace: ShowIntercomSpace;
  showIntercomArticle: ShowIntercomArticle;
  startIntercomTour: StartIntercomTour;
  startIntercomChecklist: StartIntercomChecklist;
  updateIntercom: UpdateIntercom;
};

export const IntercomContext = createContext<IntercomContextType>(
  {} as IntercomContextType
);

export function useIntercom(): IntercomContextType {
  return useContext(IntercomContext);
}
