import { useMutation } from '@tanstack/react-query';
import { reggieClient } from './client';

export interface PurchaseCleanupInput {
  name: string;
  quantity: number;
  unit: string;
}

export interface PurchaseCleanupResponse {
  cleanName: string;
  itemUnit: string;
  itemUnitWeight: number;
  itemsPerPurchase: number;
  quantity: number;
  foodType: string;
}

const purchaseCleanup = async ({
  name,
  quantity,
  unit,
}: PurchaseCleanupInput) => {
  const { data } = await reggieClient.post('/purchase-cleanup/', {
    name,
    quantity,
    unit,
  });

  return data as PurchaseCleanupResponse;
};

export const usePurchaseCleanup = () => {
  return useMutation({
    mutationFn: purchaseCleanup,
  });
};
