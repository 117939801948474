import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsActivitiesStationaryCombustionListQueryKey,
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsActivitiesDestroy,
  useCompanyReportsActivitiesStationaryCombustionCreate,
  useCompanyReportsActivitiesStationaryCombustionList,
  useCompanyReportsActivitiesStationaryCombustionRetrieve,
  useCompanyReportsActivitiesStationaryCombustionUpdate,
} from '@shared/api/lib/company-reports/company-reports';
import {
  CompanyReportsActivitiesStationaryCombustionListParams,
  PaginatedStationaryCombustionActivitiesListList,
  StationaryCombustionActivitiesList,
  StationaryCombustionActivityRetrieve,
} from '@shared/api/types';

import { useQueryClient } from '@tanstack/react-query';

export const useGetStationaryCombustionActivityList = (
  companyReportUuid: string,
  params?: CompanyReportsActivitiesStationaryCombustionListParams
) => {
  return useCompanyReportsActivitiesStationaryCombustionList<PaginatedStationaryCombustionActivitiesListList>(
    companyReportUuid,
    params,
    {
      request: useAxiosConfig(),
      query: {
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    }
  );
};

export const useCreateStationaryCombustionActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesStationaryCombustionCreate<StationaryCombustionActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesStationaryCombustionListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useGetStationaryCombustionActivity = ({
  stationaryCombustionActivityUuid,
  reportUuid,
  params,
}: {
  stationaryCombustionActivityUuid?: string;
  reportUuid: string;
  params?: CompanyReportsActivitiesStationaryCombustionListParams;
}) => {
  return useCompanyReportsActivitiesStationaryCombustionRetrieve<StationaryCombustionActivityRetrieve>(
    reportUuid,
    stationaryCombustionActivityUuid!,
    params,
    {
      request: useAxiosConfig(),
      query: {
        enabled: !!stationaryCombustionActivityUuid,
      },
    }
  );
};

export const useUpdateStationaryCombustionActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesStationaryCombustionUpdate<StationaryCombustionActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesStationaryCombustionListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useDeleteStationaryCombustionActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(
          getCompanyReportsActivitiesStationaryCombustionListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};
