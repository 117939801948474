export const useImporterTranslations = () => {
  return {
    uploadStep: {
      manifestDescription: (
        <div>
          We require names, quantities, and units, with the option to add IDs
          and suppliers. Match your data against the columns below, to ensure
          everything aligns.
          <br />
          <br />
          (If you’re unsure how to structure your spreadsheet, refer to the
          template provided.)
        </div>
      ),
    },
    alerts: {
      unmatchedRequiredFields: {
        headerTitle: 'Not all columns matched',
        bodyText:
          'There are required columns that are not matched or ignored. Please match all required columns to continue.',
        listTitle: 'Columns not matched:',
        cancelButtonTitle: 'Cancel',
        continueButtonTitle: 'Continue',
      },
    },
  };
};
