import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { useGetCompanyReport } from '@shared/api';
import { CompanyReportSummary } from '@shared/api/types';
import { PageTabs } from '@shared/components/page-tabs';
import { format } from 'date-fns';
import {
  generatePath,
  Outlet,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';
import { companyReportTabs } from './tabs';

export type CCFContext = {
  reportTitle?: string;
  reportUuid: string;
  summary?: CompanyReportSummary[];
};

export function useCCF() {
  return useOutletContext<CCFContext>();
}

export default function CCFLayout() {
  const params = useParams();
  const companyReportUuid = params.report_uuid;
  const tabs = companyReportTabs(companyReportUuid!);

  const { data: companyReport, status: companyReportStatus } =
    useGetCompanyReport(companyReportUuid!);

  const context: CCFContext = {
    reportTitle: companyReport?.title,
    reportUuid: companyReportUuid!,
    summary: companyReport?.summary,
  };

  return (
    <Page
      name="Company carbon footprint hub"
      page="layout"
      status={companyReportStatus}
      flexFullHeight
    >
      {companyReport && (
        <>
          <PageHeader
            name={companyReport?.title}
            description={`${format(companyReport.startDate, 'do MMMM yyyy')} - ${format(companyReport.endDate, 'do MMMM yyyy')}`}
            tabs={<PageTabs tabs={tabs} />}
            breadcrumbs={[
              {
                label: 'Company reports',
                url: generatePath(ccfRoutes.COMPANY_REPORTS),
              },
              {
                label: `${companyReport.title}`,
                url: generatePath(ccfRoutes.COMPANY_REPORT, {
                  report_uuid: companyReportUuid,
                }),
              },
            ]}
          />
          <Outlet context={context} />
        </>
      )}
    </Page>
  );
}
