import EmptyPageContent from '@app/ccf/components/empty-page-content';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useGetSites } from '@shared/api';
import { Site } from '@shared/api/types';
import { SupportButton } from '@shared/components/buttons/support-button';
import Text from '@shared/components/content/text';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { DataTableFilterField } from '@shared/components/data-table/types';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import SiteForm from './site-form';
import { siteTableColumns } from './sites-table-columns';

export const schema = z.object({
  page: z.coerce.number().default(1),
  page_size: z.coerce.number().optional(),
  sort: z.string().optional(),
  name: z.string().optional(),
});

export default function SitesTable() {
  const [selectedSite, setSelectedSite] = useState<Site>();
  const resetSelectedSite = () => setSelectedSite(undefined);
  const { showSimpleDialog, SimpleDialogComponent, closeSimpleDialog } =
    useSimpleDialog(resetSelectedSite);
  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));

  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetSites({
      page: search.page,
      page_size: search.page_size,
      search: search.name || '',
    });

  if (isError) throw new Error();

  const { results: sites = [], count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterFields: DataTableFilterField<Site>[] = [
    {
      label: 'Name',
      value: 'name',
      placeholder: 'Search sites...',
    },
  ];

  const { table } = useServerDataTable({
    data: sites,
    columns: siteTableColumns,
    pageCount,
    filterFields,
    defaultPerPage: 10,
    rowId: 'uuid',
    columnVisibilityState: {
      uuid: false,
      period: false,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {sites?.length === 0 ? (
        <EmptyPageContent
          title="Add your sites"
          description={
            <div className="space-y-4">
              <Text className="text-center">
                A site refers to any physical location that is part of your
                company’s operations and contributes to its carbon footprint.
              </Text>
              <Text className="text-center">
                For each of your sites, you can provide your Scope 1 and 2 data,
                such as energy readings, as monthly, quarterly or annual values.
              </Text>
            </div>
          }
          primaryAction={showSimpleDialog}
          primaryActionText="Add site"
          supportArticle="managingSites"
        />
      ) : (
        <DataTable
          table={table}
          enablePagination
          className="bg-gray-00"
          onRowClick={(row) => {
            setSelectedSite(row?.original as Site);
            showSimpleDialog();
          }}
          isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
        >
          <DataTableToolbar table={table} filterFields={filterFields}>
            <SupportButton article="managingSites" size="sm" />
            <Button size="sm" onClick={showSimpleDialog}>
              Add site
            </Button>
          </DataTableToolbar>
        </DataTable>
      )}
      {SimpleDialogComponent({
        header: `${selectedSite ? 'Edit' : 'Add'} site`,
        content: (
          <SiteForm
            site={selectedSite}
            closeDialog={() => {
              setSelectedSite(undefined);
              closeSimpleDialog();
            }}
          />
        ),
      })}
    </>
  );
}
