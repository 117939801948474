import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@shared/components/ui/form';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@shared/components/ui/select';
import { capitalizeFirstLetter, cn } from '@shared/lib/utils';
import { useFormContext } from 'react-hook-form';
import Loader from '../loader';
import InputLabel, { InputLabelProps } from './input-label';
import { FormProps } from './types';

export interface SelectOption {
  value: string;
  label: string;
  shortLabel?: string;
}

interface SelectFormFieldProps extends FormProps, InputLabelProps {
  options: SelectOption[];
  groupedOptions?: { [key: string]: SelectOption[] };
  useShortLabel?: boolean;
  disabled?: boolean;
  placeholder?: string;
  loading?: boolean;
}

export default function SelectFormField({
  name,
  includeErrorMessage = false,
  className,
  label,
  options,
  useShortLabel,
  disabled,
  groupedOptions,
  placeholder,
  tooltip,
  loading,
  optional = false,
}: SelectFormFieldProps) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn('grid items-end w-full', className)}>
          {label && (
            <InputLabel
              label={label}
              name={name}
              optional={optional}
              tooltip={tooltip}
            />
          )}
          <Select
            onValueChange={(value) => {
              field.onChange(value);
            }}
            value={field.value}
            disabled={disabled}
          >
            <FormControl>
              <SelectTrigger
                className={cn(
                  'w-full truncate data-[placeholder]:text-muted-foreground',
                  {
                    'border-destructive': fieldState.error,
                  }
                )}
              >
                {loading && <Loader size="xs" color="primary" />}
                {!loading && useShortLabel && (
                  <SelectValue placeholder={placeholder}>
                    {options.find((option) => option.value === field.value)
                      ?.shortLabel || field.value}
                  </SelectValue>
                )}
                {!loading && !useShortLabel && (
                  <SelectValue placeholder={placeholder} />
                )}
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {groupedOptions
                ? Object.keys(groupedOptions)
                    .sort()
                    .map((groupKey, i) => (
                      <SelectGroup key={groupKey}>
                        <SelectLabel>
                          {capitalizeFirstLetter(groupKey)}
                        </SelectLabel>
                        {groupedOptions[groupKey]
                          .sort((a, b) => a.label.localeCompare(b.label))
                          .map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        {i !== Object.keys(groupedOptions).length - 1 && (
                          <SelectSeparator />
                        )}
                      </SelectGroup>
                    ))
                : options.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
            </SelectContent>
          </Select>
          {includeErrorMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
