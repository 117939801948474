import { Fields, SelectOption } from 'react-spreadsheet-import/types/types';

const itemTypeValidation = () => ({
  rule: 'regex',
  value: `^(?!non_food$).*$`,
  errorMessage: `This row has been recognised as non-food`,
  level: 'error',
});

const maxLengthValidation = (length: number) => ({
  rule: 'regex',
  value: `^.{0,${length}}$`,
  errorMessage: `Must not be longer than ${length} characters`,
  level: 'error',
});

const requiredValidation = (name: string) => ({
  rule: 'required',
  errorMessage: `${name} is required`,
  level: 'error',
});

const itemTypeOptions: SelectOption[] = [
  { label: 'Simple ingredient', value: 'simple_ingredient' },
  { label: 'Composite ingredient', value: 'composite_ingredient' },
  { label: 'Non-food', value: 'non_food' },
];

export const getCleanupFoodFields: () => Fields<string> = () => [
  {
    label: 'ID',
    key: 'remoteId',
    alternateMatches: ['product id', 'sku', 'id'],
    fieldType: {
      type: 'input',
    },
    example: '#HK-TOM-1',
    validations: [maxLengthValidation(240)],
  },
  {
    label: 'Supplier',
    key: 'supplier',
    alternateMatches: ['supplier', 'vendor', 'manufacturer'],
    fieldType: {
      type: 'input',
    },
    example: 'HK Foods',
    validations: [maxLengthValidation(240)],
  },
  {
    label: 'Name',
    key: 'name',
    alternateMatches: ['title', 'description'],
    fieldType: {
      type: 'input',
    },
    example: 'Cherry tomatoes',
    validations: [requiredValidation('Name'), maxLengthValidation(240)],
  },
  {
    label: 'Quantity',
    key: 'quantity',
    alternateMatches: ['qty', 'amount'],
    fieldType: {
      type: 'input',
    },
    example: '439',
    validations: [requiredValidation('Quantity'), maxLengthValidation(30)],
  },
  {
    label: 'Unit',
    key: 'unit',
    alternateMatches: ['uom', 'units'],
    fieldType: {
      type: 'input',
    },
    example: 'kg',
    validations: [maxLengthValidation(36)],
  },
];

export const getImportFoodFields: () => Fields<string> = () => [
  {
    label: 'ID',
    key: 'remoteId',
    alternateMatches: ['product id', 'sku', 'id'],
    fieldType: {
      type: 'input',
    },
    example: '#HK-TOM-1',
    validations: [maxLengthValidation(240)],
  },
  {
    label: 'Supplier',
    key: 'supplier',
    alternateMatches: ['supplier', 'vendor', 'manufacturer'],
    fieldType: {
      type: 'input',
    },
    example: 'HK Foods',
    validations: [maxLengthValidation(240)],
  },
  {
    label: 'Name',
    key: 'name',
    alternateMatches: ['title', 'description'],
    fieldType: {
      type: 'input',
    },
    example: 'Cherry tomatoes',
    validations: [requiredValidation('Name'), maxLengthValidation(240)],
  },
  {
    label: 'Original Quantity',
    key: 'original_quantity',
    alternateMatches: ['qty', 'amount'],
    fieldType: {
      type: 'input',
    },
    example: '439',
    validations: [requiredValidation('Quantity'), maxLengthValidation(30)],
  },
  {
    label: 'Original Unit',
    key: 'unit',
    alternateMatches: ['uom', 'units'],
    fieldType: {
      type: 'input',
    },
    example: 'kg',
    validations: [maxLengthValidation(36)],
  },
  {
    label: 'Quantity',
    key: 'quantity',
    alternateMatches: ['qty', 'amount'],
    fieldType: {
      type: 'input',
    },
    example: '439',
    validations: [requiredValidation('Quantity'), maxLengthValidation(30)],
  },
  {
    label: 'Items per pack',
    key: 'itemsPerPack',
    alternateMatches: ['items per pack', 'pack size'],
    fieldType: {
      type: 'input',
    },
    example: '6',
    validations: [],
  },
  {
    label: 'Item amount',
    key: 'itemQuantity',
    alternateMatches: ['item quantity', 'quantity'],
    fieldType: {
      type: 'input',
    },
    example: '6',
    validations: [],
  },
  {
    label: 'Item unit',
    key: 'itemUnit',
    alternateMatches: ['item unit', 'unit'],
    fieldType: {
      type: 'input',
    },
    example: 'Pack',
    validations: [],
  },
  {
    label: 'Item type',
    key: 'itemType',
    alternateMatches: ['item type', 'type'],
    fieldType: {
      type: 'select',
      options: itemTypeOptions,
    },
    example: 'Single ingredient',
    validations: [itemTypeValidation(), requiredValidation('Item type')],
  },
];
