import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsActivitiesMobileCombustionListQueryKey,
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsActivitiesDestroy,
  useCompanyReportsActivitiesMobileCombustionCreate,
  useCompanyReportsActivitiesMobileCombustionList,
  useCompanyReportsActivitiesMobileCombustionRetrieve,
  useCompanyReportsActivitiesMobileCombustionUpdate,
} from '@shared/api/lib/company-reports/company-reports';
import {
  CompanyReportsActivitiesMobileCombustionListParams,
  MobileCombustionActivitiesList,
  MobileCombustionActivityRetrieve,
  PaginatedMobileCombustionActivitiesListList,
} from '@shared/api/types';

import { useQueryClient } from '@tanstack/react-query';

export const useGetMobileCombustionActivityList = (
  companyReportUuid: string,
  params?: CompanyReportsActivitiesMobileCombustionListParams
) => {
  return useCompanyReportsActivitiesMobileCombustionList<PaginatedMobileCombustionActivitiesListList>(
    companyReportUuid,
    params,
    {
      request: useAxiosConfig(),
      query: {
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    }
  );
};

export const useCreateMobileCombustionActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesMobileCombustionCreate<MobileCombustionActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesMobileCombustionListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useGetMobileCombustionActivity = ({
  mobileCombustionActivityUuid,
  reportUuid,
  params,
}: {
  mobileCombustionActivityUuid?: string;
  reportUuid: string;
  params?: CompanyReportsActivitiesMobileCombustionListParams;
}) => {
  return useCompanyReportsActivitiesMobileCombustionRetrieve<MobileCombustionActivityRetrieve>(
    reportUuid,
    mobileCombustionActivityUuid!,
    params,
    {
      request: useAxiosConfig(),
      query: {
        enabled: !!mobileCombustionActivityUuid,
      },
    }
  );
};

export const useUpdateMobileCombustionActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesMobileCombustionUpdate<MobileCombustionActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesMobileCombustionListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useDeleteMobileCombustionActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(
          getCompanyReportsActivitiesMobileCombustionListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};
