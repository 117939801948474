import { DirectGasEmissionActivitiesList } from '@shared/api/types';
import Unit from '@shared/components/content/unit';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { TextCell } from '@shared/components/data-table/cells/text-cell';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import { ColumnDef } from '@tanstack/react-table';

export const directGasTableColumns: ColumnDef<DirectGasEmissionActivitiesList>[] =
  [
    {
      accessorKey: 'uuid',
      enableHiding: true,
    },
    {
      accessorKey: 'site',
      header: ({ column }) => <HeaderCell column={column} name="Site" />,
      cell: ({ getValue }) => {
        const site = getValue<DirectGasEmissionActivitiesList['site']>();
        return <TextCell text={site.name} />;
      },
    },
    {
      accessorKey: 'source',
      header: ({ column }) => <HeaderCell column={column} name="Source" />,
      cell: ({ getValue }) => {
        const source = getValue<DirectGasEmissionActivitiesList['source']>();
        return <TextCell text={capitalizeFirstLetter(source)} />;
      },
    },
    {
      accessorKey: 'gas',
      header: ({ column }) => <HeaderCell column={column} name="Gas" />,
      cell: ({ getValue }) => {
        const gas = getValue<DirectGasEmissionActivitiesList['gas']>();
        return <TextCell text={gas.name} />;
      },
    },
    {
      accessorKey: 'emissionFactor',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Emission Factor"
          numberColumn
          unit={<Unit variant="kgCO2e/kg" />}
        />
      ),
      cell: ({ getValue }) => {
        const emissionFactor = getValue<number>();
        return <NumberCell number={emissionFactor} rounded />;
      },
    },
    {
      accessorKey: 'quantity',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Quantity"
          numberColumn
          unit={<Unit variant="kg" />}
        />
      ),
      cell: ({ getValue }) => {
        const quantity = getValue<number>();
        return <NumberCell number={quantity} rounded />;
      },
    },
    {
      accessorKey: 'emissions',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Emissions"
          numberColumn
          unit={<Unit variant="tCO2e" />}
        />
      ),
      cell: ({ getValue }) => {
        return <NumberCell number={getValue<number>()} rounded />;
      },
    },
  ];
