import useSimpleDialog from '@app/hooks/use-simple-dialog';

import { useGetMobileCombustionActivityList } from '@shared/api/hooks/ccf/activities/mobile-combustion';
import {
  CompanyReportsActivitiesMobileCombustionListSortItem,
  MobileCombustionActivitiesList,
  PaginatedMobileCombustionActivitiesListListFilters,
} from '@shared/api/types';
import { SupportButton } from '@shared/components/buttons/support-button';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { baseTableSchema } from '@shared/components/data-table/schema';
import { DataTableFilterField } from '@shared/components/data-table/types';
import { Button } from '@shared/components/ui/button';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useCCF } from '../../ccf-layout';
import MobileCombustionEditForm from './mobile-combustion-edit-form';
import { mobileCombustionTableColumns } from './mobile-combustion-table-columns';

export const schema = baseTableSchema.extend({
  uuid: z.string().optional(),
});

export default function MobileCombustionTable() {
  const { reportUuid } = useCCF();
  const [filters, setFilters] =
    useState<PaginatedMobileCombustionActivitiesListListFilters>({});
  const [selectedActivityUuid, setSelectedActivityUuid] =
    useState<MobileCombustionActivitiesList['uuid']>();
  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));
  const {
    showSimpleDialog: showMobileCombustionDialog,
    SimpleDialogComponent: MobileCombustionDialog,
    closeSimpleDialog: closeMobileCombustionDialog,
  } = useSimpleDialog();

  const suppliers = searchParams.get('supplier')?.split('.');
  const time_periods = searchParams.get('period')?.split('.');
  const sites = searchParams.get('site')?.split('.');
  const fuels = searchParams.get('fuel')?.split('.');
  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetMobileCombustionActivityList(reportUuid!, {
      page: search.page,
      page_size: search.page_size,
      suppliers,
      time_periods,
      sites,
      fuels,
      search: search.uuid || '',
      sort: [
        search.sort,
      ] as CompanyReportsActivitiesMobileCombustionListSortItem[],
    });

  if (isError) throw new Error();

  const { results: activities, count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterOptions = useMemo(() => {
    const sites =
      filters.sites?.map((filter) => {
        return {
          label: filter.name,
          value: filter.uuid,
        };
      }) || [];

    const fuels =
      filters.fuels?.map((filter) => {
        return {
          label: filter.name,
          value: filter.uuid,
        };
      }) || [];

    return {
      sites,
      fuels,
    };
  }, [filters]);

  useEffect(() => {
    if (data && !isPreviousData && isFetchedAfterMount) {
      setFilters({
        timePeriods: data.filters?.timePeriods,
        sites: data.filters?.sites,
        fuels: data.filters?.fuels,
      });
    }
  }, [data, isFetchedAfterMount, isPreviousData]);

  const filterFields: DataTableFilterField<MobileCombustionActivitiesList>[] = [
    {
      label: 'Search',
      value: 'uuid',
      placeholder: 'Search sites...',
    },
    {
      label: 'Site',
      value: 'site',
      options: filterOptions.sites,
    },

    {
      label: 'Fuel',
      value: 'fuel',
      options: filterOptions.fuels,
    },
  ];

  const { table } = useServerDataTable({
    data: activities || [],
    columns: mobileCombustionTableColumns,
    pageCount,
    filterFields,
    defaultPerPage: 10,
    rowId: 'uuid',
    columnVisibilityState: {
      uuid: false,
      period: false,
    },
  });

  return (
    <>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        onRowClick={(row) => {
          setSelectedActivityUuid(row?.original.uuid);
          showMobileCombustionDialog();
        }}
        isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
      >
        <DataTableToolbar table={table} filterFields={filterFields}>
          <SupportButton article="mobileCombustion" size="sm" />
          <Button
            size="sm"
            onClick={() => {
              setSelectedActivityUuid(undefined);
              showMobileCombustionDialog();
            }}
          >
            Add data
          </Button>
        </DataTableToolbar>
      </DataTable>
      {MobileCombustionDialog({
        header: 'Add mobile combustion data',
        content: (
          <MobileCombustionEditForm
            activityUuid={selectedActivityUuid}
            closeDialog={closeMobileCombustionDialog}
          />
        ),
      })}
    </>
  );
}
