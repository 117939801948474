import { ccfRoutes } from '@app/ccf/ccf-routes';
import { useCCF } from '@app/ccf/reports/ccf-layout';
import Navbar from '@app/components/layout/nav/navbar';
import Page from '@app/components/layout/page/page';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateSites } from '@shared/api';
import { SiteTypeEnum } from '@shared/api/types';
import Alert from '@shared/components/alert';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import GuideTemplate from '@shared/components/guide-template';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { array, object, z } from 'zod';
import { siteFormSchema } from '../../sites/components/site-form';
import { SiteRow } from '../../sites/components/site-row';

const multiSiteFormSchema = object({
  sites: array(siteFormSchema.omit({ electricitySupply: true })),
});

export default function CCFSetupSites() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { reportUuid } = useCCF();
  const { showIntercomArticle } = useIntercom();

  const { mutateAsync: createSite, isLoading: creatingSite } = useCreateSites();
  const form = useForm<z.infer<typeof multiSiteFormSchema>>({
    resolver: zodResolver(multiSiteFormSchema),
    defaultValues: {
      sites: [{ name: '', country: '', type: '' }],
    },
  });

  const onSubmit = async (formData: z.infer<typeof multiSiteFormSchema>) => {
    const data = formData.sites.map((site) => ({
      ...site,
      type: site.type as SiteTypeEnum,
    }));

    try {
      await createSite({ data });
      toast({
        title: 'Sites created',
        variant: 'success',
      });
      navigate(
        generatePath(ccfRoutes.COMPANY_REPORT, {
          report_uuid: reportUuid,
        })
      );
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'Failed to create site',
        variant: 'destructive',
      });
    }
  };

  return (
    <Page name="Sites guide" className="absolute left-0 top-0 z-10">
      <div className="flex h-full min-h-screen flex-col bg-gradient-to-r from-teal-300  to-teal-100">
        <Navbar
          logo={{
            variant: 'name',
            color: 'dark',
          }}
        />
        <div className="grid flex-1 grid-cols-1 overflow-y-auto p-2">
          <GuideTemplate
            preTitle=""
            title="Your sites"
            description={
              <Text>
                Sites are locations where you produce emissions, typically each
                site would have separate utility bills. For each of your sites,
                you can provide data such as energy readings as monthly,
                quarterly or annual values.{' '}
                <TextLink onClick={() => showIntercomArticle('managingSites')}>
                  Learn more about sites
                </TextLink>
              </Text>
            }
            primaryActionText="Next"
            primaryActionProps={{
              type: 'submit',
              onClick: form.handleSubmit(onSubmit),
              loading: creatingSite,
              disabled: creatingSite,
            }}
          >
            <Form {...form}>
              <form
                className="mx-auto mt-2 max-w-form space-y-8 text-left "
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <RepeatableFormRows
                  name="sites"
                  component={SiteRow}
                  newRowObject={{ name: '', country: '', type: '' }}
                />
              </form>
            </Form>
            <div className="flex justify-center">
              <Alert variant="info" className="mt-6 w-fit py-2">
                <div className="flex flex-row items-center space-x-2 ">
                  <Icons.info className="size-4" />
                  <Text className="text-sm">
                    You can get started by adding one site, then add more later
                  </Text>
                </div>
              </Alert>
            </div>
          </GuideTemplate>
        </div>
      </div>
    </Page>
  );
}
