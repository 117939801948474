/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import {
  useInfiniteQuery,
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CountriesListParams,
  Country
} from '../../types'
import { apiClient } from '../../client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const countriesList = (
    params?: CountriesListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<Country[]>(
      {url: `/countries/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCountriesListQueryKey = (params?: CountriesListParams,) => {
    return [`/countries/`, ...(params ? [params]: [])] as const;
    }

    
export const getCountriesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof countriesList>>, TError = unknown>(params?: CountriesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCountriesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof countriesList>>> = ({ signal, pageParam }) => countriesList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CountriesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof countriesList>>>
export type CountriesListInfiniteQueryError = unknown

export const useCountriesListInfinite = <TData = Awaited<ReturnType<typeof countriesList>>, TError = unknown>(
 params?: CountriesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCountriesListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCountriesListQueryOptions = <TData = Awaited<ReturnType<typeof countriesList>>, TError = unknown>(params?: CountriesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCountriesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof countriesList>>> = ({ signal }) => countriesList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CountriesListQueryResult = NonNullable<Awaited<ReturnType<typeof countriesList>>>
export type CountriesListQueryError = unknown

export const useCountriesList = <TData = Awaited<ReturnType<typeof countriesList>>, TError = unknown>(
 params?: CountriesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCountriesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



