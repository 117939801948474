import { Icons } from '@shared/components/content/icons';
import CountryPicker from '@shared/components/form/country-picker';
import InputFormField from '@shared/components/form/input-form-field';
import SelectFormField from '@shared/components/form/select-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { Button } from '@shared/components/ui/button';
import { FormLabel } from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import { useFormContext } from 'react-hook-form';
import siteTypeOptions from './site-type-options';

interface SiteRowProps extends RepeatableRowComponentProps {}

export function SiteRow({ index, remove }: SiteRowProps) {
  const { getValues } = useFormContext();

  return (
    <div className="grid grid-cols-12 gap-2">
      <InputFormField
        name={`sites.${index}.name`}
        label={index === 0 ? 'Name' : ''}
        className="col-span-4"
        autoComplete="false"
      />
      <CountryPicker
        className="col-span-4"
        fieldName={`sites.${index}.country`}
        label={index === 0 ? 'Country' : ''}
        onlyCcfSupported
      />
      <SelectFormField
        name={`sites.${index}.type`}
        label={index === 0 ? 'Category' : ''}
        options={siteTypeOptions}
        className={'col-span-3'}
      />
      <div className="col-span-1 grid items-end">
        {index === 0 && (
          <FormLabel className="mb-0 opacity-0">Remove</FormLabel>
        )}
        <Button
          type="button"
          variant="outline"
          size="sm"
          className={cn('size-10 disabled:opacity-30', {
            'mt-2': index === 0,
          })}
          onClick={() => remove(index)}
          disabled={getValues('sites').length === 1}
        >
          <Icons.x className="size-4" />
        </Button>
      </div>
    </div>
  );
}
