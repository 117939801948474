import { Scope3Category } from '@app/ccf/reports/scopes';
import React from 'react';
import { Badge } from '../ui/badge';

interface Scope3CategoryBadgeProps {
  category: Scope3Category;
  size?: 'sm' | 'md';
  className?: string;
}

const getCategoryLabel = (category: Scope3Category) => {
  switch (category) {
    case 'purchased-goods':
      return 'Purchased Goods & Services';
    default:
      return '';
  }
};

const Scope3CategoryBadge: React.FC<Scope3CategoryBadgeProps> = ({
  category,
  size = 'md',
  className,
}) => {
  return (
    <Badge className={className} variant="primary" size={size}>
      {getCategoryLabel(category)}
    </Badge>
  );
};

export default Scope3CategoryBadge;
