import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import { useCompanyReportsActivityTypesUnitsList } from '@shared/api/lib/company-reports/company-reports';
import {
  ActivityUnit,
  CompanyReportsActivityTypesUnitsListParams,
} from '@shared/api/types';

export const useGetActivityTypeUnits = (
  activityType: string,
  params?: CompanyReportsActivityTypesUnitsListParams,
  queryOptions?: { enabled?: boolean }
) => {
  return useCompanyReportsActivityTypesUnitsList<ActivityUnit[]>(
    activityType,
    params,
    {
      request: useAxiosConfig(),
      query: {
        staleTime: Infinity,
        ...queryOptions,
      },
    }
  );
};
