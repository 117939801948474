/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `fridges` - Fridges
* `air conditioning` - Air conditioning
* `fermentation` - Fermentation
* `fire extinguishers` - Fire extinguishers
* `other` - Other
 */
export type SourceEnum = typeof SourceEnum[keyof typeof SourceEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SourceEnum = {
  fridges: 'fridges',
  air_conditioning: 'air conditioning',
  fermentation: 'fermentation',
  fire_extinguishers: 'fire extinguishers',
  other: 'other',
} as const;
