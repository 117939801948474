import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import { useCompanyReportsGasesList } from '@shared/api/lib/company-reports/company-reports';
import { CompanyReportsGasesListParams } from '@shared/api/types';

export const useGetGases = (
  params?: CompanyReportsGasesListParams,
  queryOptions?: { enabled?: boolean }
) =>
  useCompanyReportsGasesList(params, {
    request: useAxiosConfig(),
    query: {
      staleTime: Infinity,
      ...queryOptions,
    },
  });
