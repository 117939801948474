import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import Text from '@shared/components/content/text';
import { useMemo } from 'react';
import { useCCF } from '../../ccf-layout';
import HeatingEditForm from '../../components/scope-2/heating-edit-form';
import HeatingTable from '../../components/scope-2/heating-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function HeatingPage() {
  const { summary } = useCCF();
  const {
    showSimpleDialog: showHeatingDialog,
    SimpleDialogComponent: HeatingDialog,
    closeSimpleDialog: closeHeatingDialog,
  } = useSimpleDialog();

  const categoryStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'heat')[0].status,
    [summary]
  );

  const description = (
    <>
      <Text>
        Heating and steam refers to the{' '}
        <span className="font-semibold">energy your business purchases</span>{' '}
        externally to power operations, heat buildings, or support processes
        like sterilisation and production. These energy purchases result in
        indirect emissions (Scope 2) generated during the production of heating
        and steam.
      </Text>
      <Text className="mt-3">Examples include:</Text>
      <Text>
        <ul className="list-disc pl-5">
          <li>
            <span className="font-semibold">Heat generated</span> at a central
            plant and distributed via a network to your facilities.
          </li>
          <li>
            <span className="font-semibold">Steam purchased</span> for
            industrial or operational uses, such as cleaning, sterilisation, or
            production.
          </li>
        </ul>
      </Text>
    </>
  );

  return (
    <Page name="Heating & Steam">
      {categoryStatus != 'unstarted' ? (
        <HeatingTable />
      ) : (
        <EmptyCategoryPage
          categorySlug="heat"
          description={description}
          primaryActionProps={{
            onClick: showHeatingDialog,
          }}
          supportArticle="heating"
        />
      )}
      {HeatingDialog({
        header: 'Add heating and steam data',
        content: <HeatingEditForm closeDialog={closeHeatingDialog} />,
      })}
    </Page>
  );
}
