import { ScopeEnum } from '@shared/api/types';
import React from 'react';
import { Badge } from '../ui/badge';

interface ScopeBadgeProps {
  scope: ScopeEnum;
  className?: string;
}

const getScopeLabel = (scope: ScopeEnum) => {
  switch (scope) {
    case ScopeEnum.NUMBER_1:
      return 'Scope 1';
    case ScopeEnum.NUMBER_2:
      return 'Scope 2';
    case ScopeEnum.NUMBER_3:
      return 'Scope 3';
    case ScopeEnum.Out_of_scope:
      return 'Out of scope';
    default:
      return '';
  }
};

const ScopeBadge: React.FC<ScopeBadgeProps> = ({ scope, className }) => {
  return (
    <Badge className={className} variant="primary">
      {getScopeLabel(scope)}
    </Badge>
  );
};

export default ScopeBadge;
