import Page from '@app/components/layout/page/page';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import GuideTemplate from '@shared/components/guide-template';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../../ccf-routes';

export default function CCFSetupWelcome() {
  const navigate = useNavigate();
  const { openNewIntercomMessage, showIntercomSpace } = useIntercom();

  return (
    <Page name="Report welcome page">
      <GuideTemplate
        title="Company Carbon Footprint"
        description={
          <div className="space-y-4">
            <Text className="font-bold">
              Welcome to your Company Carbon Footprint!
            </Text>
            <Text>
              We’re here to make reporting simpler and more accessible for you.
              Our platform offers clear, step-by-step instructions and practical
              tips to ensure you gain fast and accurate results.
            </Text>
            <Text>
              If you have any questions, our team is a click away. You can
              search our{' '}
              <TextLink onClick={() => showIntercomSpace('help')}>
                help center
              </TextLink>{' '}
              or ask questions via{' '}
              <TextLink onClick={() => openNewIntercomMessage()}>chat</TextLink>{' '}
              for further support.
            </Text>
          </div>
        }
        primaryActionProps={{
          onClick: () => navigate(generatePath(ccfRoutes.REPORT_SETUP)),
        }}
        primaryActionText="Get started"
      >
        <div className="size-full bg-gray-100"></div>
      </GuideTemplate>
    </Page>
  );
}
