import { ccfRoutes } from '@app/ccf/ccf-routes';
import Page from '@app/components/layout/page/page';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import Text from '@shared/components/content/text';
import TextLink from '@shared/components/content/text-link';
import GuideTemplate from '@shared/components/guide-template';
import { buttonVariants } from '@shared/components/ui/button';
import { cn } from '@shared/lib/utils';
import { generatePath, useNavigate } from 'react-router-dom';
import FoodImportTable from '../components/food-import-table';
import { useFoodImport } from '../food-import-layout';

export default function FoodDownloadTemplatePage() {
  const { reportUuid } = useFoodImport();
  const { showIntercomArticle } = useIntercom();
  const navigate = useNavigate();
  const goToNextPage = () =>
    navigate(generatePath(ccfRoutes.IMPORT_FOOD, { report_uuid: reportUuid }));

  return (
    <Page name="Food and drink import begin page">
      <GuideTemplate
        preTitle="IMPORT FOOD PURCHASES"
        title="Prepare your data"
        description={
          <div className="space-y-4 ">
            <Text>
              Getting this step right is essential to ensuring your CCF report
              is accurate and actionable. To help you prepare, we’ve provided a
              downloadable spreadsheet template to make sure your data is
              formatted correctly.
            </Text>

            <Text>
              <b>First-time importers:</b> we highly recommend reading our{' '}
              <TextLink onClick={() => showIntercomArticle('foodImport')}>
                data guide
              </TextLink>{' '}
              before starting.
            </Text>
          </div>
        }
        primaryActionProps={{
          onClick: goToNextPage,
        }}
        primaryActionText="I'm ready to import"
        secondaryActionText={
          <a
            className={cn(
              buttonVariants({ variant: 'outline', size: 'default' }),
              'border-primary-700'
            )}
            href="https://myemissions-platform-production.s3.eu-west-2.amazonaws.com/template-files/food-import-template.xlsx"
          >
            Download template
          </a>
        }
        secondaryActionProps={{
          asChild: true,
        }}
      >
        <div className="rounded-lg border border-teal-200 shadow-lg">
          <FoodImportTable />
        </div>
      </GuideTemplate>
    </Page>
  );
}
