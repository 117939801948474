/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `1` - Scope 1
* `2` - Scope 2
* `3` - Scope 3
* `Out of scope` - Out of scope
 */
export type ScopeEnum = typeof ScopeEnum[keyof typeof ScopeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScopeEnum = {
  NUMBER_1: '1',
  NUMBER_2: '2',
  NUMBER_3: '3',
  Out_of_scope: 'Out of scope',
} as const;
