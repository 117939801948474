import Navbar from '@app/components/layout/nav/navbar';
import Page from '@app/components/layout/page/page';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';

export default function CCFGuideLayout() {
  const navigate = useNavigate();
  return (
    <Page name="CCF guide" page="layout" className="absolute left-0 top-0 z-10">
      <div className="flex h-full min-h-screen flex-col bg-gradient-to-r from-teal-300  to-teal-100">
        <Navbar
          logo={{
            variant: 'name',
            color: 'dark',
          }}
          includeCloseButton
          closeButtonProps={{
            onClick: () => {
              navigate(generatePath(ccfRoutes.COMPANY_REPORTS));
            },
          }}
        />
        <div className="grid flex-1 grid-cols-1 overflow-y-auto p-2">
          <Outlet />
        </div>
      </div>
    </Page>
  );
}
