import { ccfRoutes } from '@app/ccf/ccf-routes';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateSite,
  useGetElectricitySupplies,
  useUpdateSite,
} from '@shared/api';
import { Site, SiteTypeEnum } from '@shared/api/types';
import TextLink from '@shared/components/content/text-link';
import CountryPicker from '@shared/components/form/country-picker';
import InputFormField from '@shared/components/form/input-form-field';
import SelectFormField from '@shared/components/form/select-form-field';
import { Button } from '@shared/components/ui/button';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { object, string, z } from 'zod';
import siteTypeOptions from './site-type-options';

interface SiteFormProps {
  site?: Site;
  closeDialog: () => void;
}

export const siteFormSchema = object({
  name: string().min(1),
  type: string().min(1),
  country: string().min(1),
  electricitySupply: string().optional(),
});

export default function SiteForm({ site, closeDialog }: SiteFormProps) {
  const editing = !!site;
  const { toast } = useToast();
  const { showIntercomArticle } = useIntercom();
  const { mutateAsync: createSite, isLoading: creatingSite } = useCreateSite();
  const { mutateAsync: updateSite, isLoading: updatingSite } = useUpdateSite();

  const { electricitySupply, ...initialSiteData } = site || {};
  const form = useForm<z.infer<typeof siteFormSchema>>({
    resolver: zodResolver(siteFormSchema),
    defaultValues: {
      ...initialSiteData,
      ...(electricitySupply && {
        electricitySupply,
      }),
    },
  });

  const country = form.watch('country');
  const { data: suppliesData } = useGetElectricitySupplies({
    page: 1,
    page_size: 1000,
    country,
    has_certificate: true,
  });

  const supplyOptions = useMemo(() => {
    if (!suppliesData || !suppliesData.results || !country) return [];
    return suppliesData.results.map((supply) => ({
      label: supply.name,
      value: supply.uuid,
    }));
  }, [country, suppliesData]);

  const onSubmit = async (formData: z.infer<typeof siteFormSchema>) => {
    try {
      const data = {
        ...formData,
        type: formData.type as SiteTypeEnum,
      };

      if (editing) {
        await updateSite({
          uuid: site.uuid,
          data,
        });
      } else {
        await createSite({ data });
      }
      toast({
        title: `Site ${editing ? 'updated' : 'created'}`,
        variant: 'success',
      });
      closeDialog();
    } catch (error) {
      sentry.log(error);
      toast({
        title: `Failed to ${editing ? 'update' : 'create'} site`,
        variant: 'destructive',
      });
    }
  };

  const loading = creatingSite || updatingSite;

  return (
    <Form {...form}>
      <form className="mt-2 space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12 gap-2">
          <InputFormField
            name="name"
            label="Name"
            className="col-span-5"
            autoComplete="false"
          />
          <CountryPicker
            className="col-span-4"
            disabled={editing}
            onlyCcfSupported
          />
          <SelectFormField
            name="type"
            label="Category"
            options={siteTypeOptions}
            className={'col-span-3'}
          />
        </div>
        <div className="grid grid-cols-12 gap-2">
          <SelectFormField
            name="electricitySupply"
            label="Electricity supply"
            options={supplyOptions}
            disabled={!supplyOptions.length}
            placeholder="Standard location-based"
            className="col-span-5"
            tooltip={
              <p className="max-w-[320px]">
                We recommend providing a specific emissions factor from your
                electricity provider, if available.{' '}
                <TextLink
                  onClick={() => showIntercomArticle('electricitySupplies')}
                >
                  Learn more
                </TextLink>
              </p>
            }
            optional
          />
        </div>
        <Link
          to={ccfRoutes.ELECTRICITY_SUPPLIES}
          className="col-span-4 mb-1 self-end"
          target="_blank"
        >
          <Button type="button" size="sm" variant="link">
            Manage your supplies &rarr;
          </Button>
        </Link>
        <div className="flex justify-between">
          <div className="col-span-4">
            {/* <Button
              type="button"
              size="lg"
              variant="destructive"
              loading={isDeletingFoodActivity}
              disabled={isDeletingFoodActivity || isUpdatingFoodActivity}
              onClick={handleDelete}
            >
              Delete item
            </Button> */}
          </div>
          <div className="flex space-x-2">
            <div className="col-span-4">
              <Button
                type="button"
                size="lg"
                variant="secondary"
                onClick={() => {
                  closeDialog();
                  form.reset();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
            <div className="col-span-4">
              <Button
                type="submit"
                size="lg"
                loading={loading}
                disabled={loading}
              >
                {editing ? 'Update site' : 'Create site'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
