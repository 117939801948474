import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import { useCompanyReportsFuelsList } from '@shared/api/lib/company-reports/company-reports';
import { CompanyReportsFuelsListParams } from '@shared/api/types';

export const useGetFuels = (
  params?: CompanyReportsFuelsListParams,
  queryOptions?: { enabled?: boolean }
) =>
  useCompanyReportsFuelsList(params, {
    request: useAxiosConfig(),
    query: {
      staleTime: Infinity,
      ...queryOptions,
    },
  });
