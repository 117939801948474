import { Icons } from '@shared/components/content/icons';
import {
  Alert as AlertComponent,
  AlertDescription,
  AlertTitle,
} from '@shared/components/ui/alert';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface AlertProps {
  variant: 'info' | 'warning';
  className?: ClassValue;
  title?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const variantStyles = {
  info: {
    classNames: 'border-teal-200 bg-teal-50 text-teal-700',
    icon: <Icons.info className="size-4" />,
  },
  warning: {
    classNames: 'border-warning-200 bg-warning-50 text-warning-900 text-sm',
    icon: <Icons.warning className="size-4" />,
  },
};

export default function Alert({
  variant,
  className,
  title,
  icon,
  children,
}: AlertProps) {
  const defaultIcon = variantStyles.info.icon;

  return (
    <AlertComponent
      className={cn('px-4 py-3', variantStyles[variant].classNames, className)}
    >
      {title && (
        <AlertTitle className="flex items-center space-x-2 text-sm font-semibold">
          {icon || defaultIcon} <span>{title}</span>
        </AlertTitle>
      )}
      <AlertDescription>{children}</AlertDescription>
    </AlertComponent>
  );
}
