import { cn } from '@shared/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { Matcher } from 'react-day-picker';
import { useFormContext } from 'react-hook-form';
import { Button } from '../ui/button';
import { Calendar, CalendarProps } from '../ui/calendar';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { FormProps } from './types';

type DatePickerFormFieldProps = CalendarProps &
  FormProps & {
    disabledDates?: Matcher | Matcher[];
    disabled?: boolean;
    handleSelect?: () => void;
    placeholder?: string;
    isCalenderOpen?: boolean;
    setIsCalenderOpen?: (isOpen: boolean) => void;
  };

export default function DatePickerFormField({
  name,
  className,
  label,
  includeErrorMessage = false,
  disabled,
  handleSelect,
  placeholder = 'Pick a date',
  isCalenderOpen,
  setIsCalenderOpen,
  ...props
}: DatePickerFormFieldProps) {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('flex flex-col space-y-0', className)}>
          {label && (
            <FormLabel className="mb-2 mr-auto" htmlFor={name}>
              {label}
            </FormLabel>
          )}
          <Popover
            open={isCalenderOpen && isCalenderOpen}
            onOpenChange={setIsCalenderOpen && setIsCalenderOpen}
            modal={true}
          >
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-full h-10 pl-3 text-left font-normal',
                    !field.value && 'text-muted-foreground'
                  )}
                  disabled={disabled}
                >
                  {field.value ? (
                    format(field.value, 'PPP')
                  ) : (
                    <span>{placeholder}</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                captionLayout="dropdown-buttons"
                selected={field.value}
                onSelect={async (date) => {
                  field.onChange(date);
                  handleSelect && handleSelect();
                }}
                defaultMonth={props.defaultMonth}
                disabled={props.disabledDates}
                showOutsideDays={props.showOutsideDays}
                fromYear={props.fromYear}
                toYear={props.toYear}
                required
                initialFocus
              />
            </PopoverContent>
          </Popover>
          {includeErrorMessage && <FormMessage className="text-left" />}
        </FormItem>
      )}
    />
  );
}
