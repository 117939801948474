import Page from '@app/components/layout/page/page';
import SitesTable from '../components/sites-table';

export default function SitesPage() {
  return (
    <Page name="Sites" flexFullHeight>
      <SitesTable />
    </Page>
  );
}
