import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@shared/components/ui/accordion';

interface HubAccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  value: string;
}

export default function HubAccordionItem({
  id,
  title,
  value,
  children,
}: HubAccordionProps) {
  return (
    <AccordionItem id={id} value={value} className="max-w-form border-b-0">
      <AccordionTrigger className="rounded-t-md border-2 border-b-primary-700 bg-primary-100 px-3 py-1 text-[16px] text-primary-700 hover:no-underline">
        {title}
      </AccordionTrigger>
      <AccordionContent className="space-y-2 px-3 py-2">
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}
